import React from 'react';
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import  { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import emailjs from '@emailjs/browser';
//import comps
import "./contactForm.css";
import Header from './header.js'
import Footer from './footer.js'
//import img
import logo from './../images/BlueLogo.svg'
import telImg from './../images/tel.svg'
import mailImg from './../images/mail.svg'


function ContactForm() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);

    const {t} = useTranslation();
    const [name,setName] = useState('');
    const [sirName,setSirName] = useState('');
    const [phone,setPhone] = useState('');
    const [email,setEmail] = useState('');
    const [message,setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [canSubmit,setCanSubmit] = useState(false);
    const [isValid,setIsValid] = useState(false);

      //////phone///////////////////////////////////
  const handleChangePhone = (event) => {
    // Allow only numbers and backspace
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      setPhone(event.target.value);
    }

    // Update validity based on length
    setIsValid(event.target.value.length >= 10);
  };


    useEffect(() => {
    
        if (name!=='' && sirName!=='' && isValid && email!=='' && message!==''){
            setCanSubmit(true);     
        }
        else{
            setCanSubmit(false)
        }
      }, [name,sirName,phone,email,message]);


    const handleSumbit = (e) =>{
        e.preventDefault();
        setSubmitted(true);

        const serviceId ='service_qetsjyd';
        const templateId ='template_moz9fn9';
        const publicKey ='vWUXH83DJ5Mlc2nQs';

        const templateParams ={
            from_name : name,
            from_sirName: sirName,
            from_email: email,
            reply_to:email,
            from_phone: phone,
            to_name: 'Dragonfly Kayak',
            message: message,
        };
    
        emailjs.send(serviceId, templateId, templateParams, publicKey)
        .then((response) => {
            console.log('Email send successfully!', response);
            setName('');
            setSirName('');
            setPhone('');
            setEmail('');
            setMessage('');
        })
        .catch((error) => {
            console.error('Error sending email:', error);
        });
    }

    

  return (
    <div>
        <Header/>
        <div className='col-12 contactFormImg'>
            <div className="mainFormDiv d-flex flex-column col-12 col-md-10 col-xl-6 mx-auto justify-content-center py-5">
                <form className='w-100' onSubmit={handleSumbit} >

                    <div className='d-flex flex-column col-11 align-items-center gap-3 mx-auto'>
                        <div className='col-12 d-flex flex-row  imgTitleHolder'>
                            <div>
                                <img src={logo} alt="Dragonfly kayak in Acheron logo"/>
                            </div>
                            <div>
                                <h4>{t("contactForm.title")}</h4>
                                <p>{t("contactForm.sub")}</p>
                            </div>
                        </div>
                        <div className='col-12 d-flex flex-column flex-lg-row justify-content-between gap-3 gap-lg-0'>
                            <div className=' col-12 col-lg-5'>
                                <label for="name">{t("BookingForm.name")}</label>
                                <input type="text" className="form-control" id="name" placeholder={t("BookingForm.namePH")} onChange={(e)=> setName(e.target.value)} required value={name} />
                            </div>
                        <div className='col-12 col-lg-5'>
                            <label for="sirName">{t("BookingForm.surName")}</label>
                            <input type="text" className="form-control" id="sirName" placeholder={t("BookingForm.surNamePH")} required value={sirName} onChange={(e)=> setSirName(e.target.value)} />
                        </div>
                        </div>
                        <div className='col-12 d-flex flex-column flex-lg-row justify-content-between gap-3 gap-lg-0'>
                            <div className='col-12 col-lg-5'>
                            <label for="phoneNumber">{t("BookingForm.tel")}</label>
                            <input type="number" className="form-control" id="phoneNumber" placeholder="0030 306940524341" required value={phone} onChange={handleChangePhone} />
                        </div>
                        <div className='col-12 col-lg-5'>
                            <label for="mail">Email*</label>
                            <input type="text" className="form-control" id="mail" placeholder="Dragonflykayak@yahoo.com" required value={email} onChange={(e)=> setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-12 d-flex flex-column flex-lg-row justify-content-between gap-3 gap-lg-0'>
                        <div className="col-12">
                            <label for="message">{t("BookingForm.message")}</label>
                            <textarea  value={message} onChange={(e)=> setMessage(e.target.value)} className="form-control" id="message" rows="5"></textarea>
                        </div>
                    </div>
                    <div>
                        <div className="form-group">
                            <div className="g-recaptcha" data-sitekey="YOUR_SITE_KEY"></div>
                        </div>
                    </div>
                    <div className='col-12 d-flex flex-column flex-lg-row justify-content-between gap-3 gap-lg-0'>
                        <div className=" d-flex col-12 justify-content-center justify-content-lg-end">
                            <button type="submit" className={`disabledButton ${canSubmit ? ' myBtn' : ''}`} disabled={!canSubmit}>{t("BookingForm.send")}</button>
                        </div>
                        </div>
                    </div>

                </form>
                <hr></hr>
                <div className='col-12 d-flex flex-column  justify-content-center gap-2  mt-2'>
                    <div className='d-flex'> <img src={telImg}  alt='kayak Parga tel' className='mx-2' /> <p>{t('submit.7')} </p> <a className='subLinks' href='tel:+30 694 052 4341'> 00306940524341</a></div>
                    <div className='d-flex'><img src={mailImg} alt='kayak Parga Email' className='mx-2'/> <p>Email: </p> <a className='subLinks' href="mailto:dragonflykayak@yahoo.com">dragonflykayak@yahoo.com </a></div>
                </div>
            </div>
        </div>
    <Footer/>
    </div>
  );
}

export default ContactForm;
