import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import i18n from './comps/i18n'; // Import your i18n instance
import { I18nextProvider } from 'react-i18next';
//import components
import TourAcheronEscape from './comps/tourAcheronEscape';
import TourLitleAcheron from './comps/tourLitleAcheron';
import TourOdyssey from './comps/tourOdyssey';
import LandingPage from './comps/landingPage';
import ContactForm from './comps/contactForm';
import BookingPage from './comps/bookToursDetails'
import BookingForm from './comps/bookingForm';
import PickupPage from './comps/pickupPage';
import SubmitPage from './comps/submitPage';
import FirstBooking from './comps/firstBooking';
import SecBooking from './comps/secBooking';
import BookingFormTransfer from './comps/bookingTransfer';
const router = createBrowserRouter([

  {
    path:'/',
    element:<LandingPage/>
  },
  {
    path:'/Acheron-Escape-kayak-tour',
    element:<TourAcheronEscape/>
  },
  {
    path:'/Contact-Form',
    element:<ContactForm/>
  },
  {
    path:'/Kayak-Tour-Book',
    element:<BookingPage/>
  },
  {
    path:'/Booking-form',
    element:<BookingForm/>
  },
  {
    path:'/Pickup-Schedule',
    element:<PickupPage/>
  },
  {
    path:'/Litle-Acheron-kayak-tour',
    element: <TourLitleAcheron/>
  },
  {
    path:'/Odyssey-kayak-tour',
    element:<TourOdyssey/>
  },
  {
    path:'/submited',
    element:<SubmitPage/>
  },
  {
    path:'/booking-First-Step',
    element:<FirstBooking/>
  },
  {
    path:'/booking-Secont-Step',
    element:<SecBooking/>
  },
  {
    path:'/booking-form-transfer',
    element:<BookingFormTransfer/>
  }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>  
    <I18nextProvider i18n={i18n}>
      <RouterProvider router={router} />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
