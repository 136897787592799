import React from 'react';
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./../../node_modules/bootstrap/dist/js/bootstrap.bundle.js"
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import "./toursBookNow.css"
import { useNavigate } from 'react-router-dom';
//import icons 
import clockImg from './../images/tours-icons/clock.svg'
import personImg from './../images/tours-icons/person.svg'
import diffImg from './../images/tours-icons/diff.svg'
import dropletImg from './../images/tours-icons/droplet-half.svg'
import familyImg from './../images/tours-icons/family.svg'
import kayakImg from './../images/tours-icons/kayak.svg'
import mapImg from './../images/tours-icons/map.svg'
import waterImg from './../images/tours-icons/water_bottle.svg'



const ToursBookNow = ({title,detail,img,hours,tourLink,navLink, tourNum}) => {
    const {t} = useTranslation();
    const handleNext = () => {
        navigate(navLink, { state: { toursTitle:title , hours:hours, img:img , tourNum:tourNum} }); 
      };



    // keep data for the booking page
  const navigate = useNavigate();
 


    return (
        <div className=' px-lg-1 col-12 col-xl-8 mx-auto toursMainMainDiv'> 
        <div className='d-flex flex-lg-row flex-column col-12  toursMainDiv'>
            <div className='col-lg-4 col-12 d-flex'>
                <img src={img} alt='Parga kayak'/>
            </div>
            <div className=' d-flex flex-column col-lg-8 col-12 justify-content-between px-lg-2 px-1'>
                <h2>{title}</h2>
                <div className='d-flex flex-row col-12 justify-content-between'>
                    <div className='d-flex flex-column  px-1 col-4 px-lg-0 col-lg-4 gap-2 justify-content-around'>
                        <div className='d-flex felx-row col-12 align-items-center gap-2 imgPDiv'>
                            <img src={clockImg} alt='Parga kayak' className='tourDetIcons'/>
                            <p>{detail?.[0]}</p>
                        </div>
                        <div className='d-flex felx-row col-12 align-items-center gap-2 imgPDiv'>
                            <img src={personImg} alt='Parga kayak' className='tourDetIcons'/>
                            <p>{detail?.[1]}</p>
                        </div>
                        <div className='d-flex felx-row col-12 align-items-center gap-2 imgPDiv'>
                            <img src={mapImg} alt='Parga kayak' className='tourDetIcons'/>
                            <p>{detail?.[2]}</p>
                        </div>
                    </div>
                    <div className='d-flex flex-column px-1 col-4 px-lg-0 col-lg-4 gap-2 justify-content-around'>
                        <div className='d-flex felx-row col-12 align-items-center gap-2 imgPDiv'>
                            <img src={diffImg} alt='Parga kayak' className='tourDetIcons'/>
                            <p>{detail?.[3]}</p>
                        </div>
                        <div className='d-flex felx-row col-12 align-items-center gap-2 imgPDiv'>
                            <img src={dropletImg} alt='Parga kayak' className='tourDetIcons'/>
                            <p>{detail?.[4]}</p>
                        </div>
                        <div className='d-flex felx-row col-12 align-items-center gap-2 imgPDiv'>
                            <img src={kayakImg} alt='Parga kayak' className='tourDetIcons'/>
                            <p>{detail?.[5]}</p>
                        </div>
                    </div>
                    <div className='d-flex flex-column px-1 col-4 px-lg-0 col-lg-4 gap-2 justify-content-around'>
                        <div className='d-flex felx-row col-12 align-items-center gap-2 imgPDiv'>
                            <img src={familyImg} alt='Parga kayak' className='tourDetIcons'/>
                            <p>{detail?.[6]}</p>
                        </div>
                        <div className='d-flex felx-row col-12 align-items-center gap-2 imgPDiv'>
                            <img src={waterImg} alt='Parga kayak' className='tourDetIcons'/>
                            <p>{detail?.[7]}</p>
                        </div>
                    <Link to={tourLink} className='bookLinks'>{t('bookNowpage.9')}</Link>
                    </div>
                </div>
                <div  className='d-flex flex-column flex-lg-row col-12 gap-3 gap-lg-0 justify-content-between mt-4'>
                    <div className='d-flex flex-column col-12 col-lg-6 align-items-start'>
                        <p className='totalPriceP'>{t('bookNowpage.10')}</p>
                        <div className='d-flex flex-row hours'>
                            {hours.map((item) => (
                                <p className='toursHours' key={item}>{item}</p>
                            ))}
                        </div>
                    </div>
                    <div  className='col-4  col-lg-2 d-flex justify-content-lg-end justify-content-start align-items-end'>
                    <button className='enabledButton' onClick={handleNext}>Next</button>
                    </div>
                </div>
            </div>
        </div>
         <hr/>
         </div>
    );
};
  export default ToursBookNow;