import React, { useState, useEffect } from 'react';
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//
import Header from './header.js';
import Footer from './footer.js';
//
import "./firstBooking.css";
//
import backArrow from '../images/arrowBack.svg'

const FirstBooking = () => {
    const {t} = useTranslation();


    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);

    const location = useLocation();
    const { toursTitle,hours,tourNum} = location.state || {}; // Access passed data

    const [transfer, setTransfer] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (transfer !== null) {
            if (transfer) {
                navigate('/booking-Secont-Step', { state: { toursTitle:toursTitle, transfer: transfer, tourNum:tourNum } });
            } else {
                navigate('/Booking-form', { state: { toursTitle:toursTitle , hours:hours, tourNum:tourNum } }); 
            }
        }
    }, [transfer, navigate]);

    const handleYesClick = () => {
        setTransfer(true);
    };

    const handleNoClick = () => {
        setTransfer(false);
    };


    const handleGoBack = () => {
        if (location.state && location.state.from) {
          navigate(location.state.from);
        } else {
          navigate(-1);  // Fallback to go back one step in the browser history
        }
      };


    return (
        <div className='vh-100 d-block'>
            <Helmet>
        <title>Dragonfly Kayak: Book Kayak in Acheron</title>
        <meta name="description" content="Book the Best Acheron kayak Experience with Transfer from Parga Kanali and Preveza" />

        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WC3GL5Z');
          `}
        </script>
      </Helmet>
            <Header/>
            <div className='d-flex flex-column justify-content-center align-items-center fbMainDiv'>
                <div className='d-flex flex-column gap-3 px-3 py-5 col-11 col-xl-4 rounded fbInnerDiv'>
                <div className='d-flex '>
                        <button onClick={handleGoBack} className='backBtn col-1 align-items-center'>
                            <img src={backArrow }></img>
                        </button>
                        <div className='d-flex flex-row col-6 gap-2 mx-auto justify-content-start align-items-center '>
                            
                            <div className='col-3 step-item step-item-active'></div>
                            <div className='col-3 step-item '></div>
                            <div className='col-3 step-item'></div>
                        </div>
                    </div>
                    <h2 className='color-white mx-auto'>{t('transferBooking.1')}</h2>
                    <div className='d-flex flex-column flex-xl-row col-12 col-xl-4 mx-auto gap-3 justify-content-center'>
                    <button type="button" onClick={handleYesClick} class="btn  px-2 px-xl-4">{t('transferBooking.yes')}</button>
                    <button type="button" onClick={handleNoClick} class="btn  px-2 px-xl-4">{t('transferBooking.no')}</button>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default FirstBooking;


