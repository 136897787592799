import {React,useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//import css
import './submitPage.css'
// import comp
import Header from './header';
//importing img
import logo from './../images/BlueLogo.svg'
import AEImg from './../images/acheronEscape/ExtendedTourImage.webp'
import LAImg from './../images/littleAcheron/litleAcheron.webp'
import OdImg from './../images/odyssey/OddysseyPhoto.webp'
import telImg from './../images/tel.svg'
import mailImg from './../images/mail.svg'


const SubmitPage = () => {
    const {t} = useTranslation();
    const location = useLocation();
    const { totalPrice,transfer, toursTitle,numOfPeople,time, date,name, tel, email,pickUpValue} = location.state || {}; // Access passed data
    const [img,setImg] = useState('')

    useEffect(() => {
        if (toursTitle) {
          let selectedimg;
          switch (toursTitle) {
            case 'Acheron Escape':
            case 'Απόδραση στον Αχέροντα': // Greek for Acheron Escape
              selectedimg = AEImg;
              break;
            case 'Little Acheron':
            case "Μικρός Αχέροντας": // Greek for Little Acheron
              selectedimg = LAImg;
              break;
            case 'Odyssey':
            case 'Οδύσσεια': // Greek for Odyssey
              selectedimg = OdImg;
              break;
            default:
              // Handle cases where toursTitle doesn't match any option
              console.warn(`Unknown tour title: ${toursTitle}`);
          }
          setImg(selectedimg);
        }
      }, [toursTitle]);
      
     
   
    return (
        <div>
          <Helmet>
        <title>Dragonfly Kayak: submited</title>
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WC3GL5Z');
          `}
        </script>
      </Helmet>
        <Header/>
        <img src={img} className='backgroundImg' alt='kayak in Parga Acheron'/>
        <div className=' px-3 py-3 col-12 col-lg-5 mx-auto d-flex flex-column mainFormDiv'>

            <div className='mx-2 d-flex flex-row  imgTitleHolder align-items-center align-self-start'>        
                <img src={logo} alt='dragonfly kayak logo'/>
                <h2>{t('submit.title')}</h2>
            </div>
            <p className='basicP mb-3'>{t('submit.1')}</p>
            <div className='col-12 d-flex flex-column flex-lg-row gap-3'>
                <img src={img} alt='kayak in Parga Acheron' className='subimg col-10 col-lg-6 align-self-center '/>
                <div className='d-flex col-12 col-lg-auto flex-row flex-lg-column'>
                    <div className='col-6 col-lg-auto d-flex flex-column '>
                    <h5 className='mb-1'>{toursTitle}</h5>
                        <div>
                            <p>  {t('submit.2')} {date}</p>
                            <p>  {t('submit.3')} {time}</p>
                            <p>  {t('submit.10')} {numOfPeople}</p>
                            {transfer &&(
                              <div>
                                <p>{t('submit.14')} {pickUpValue}</p>
                              </div>
                            )}
                            <p>  {t('submit.4')} {totalPrice}€ {transfer &&(
                              <div>
                                <p>{totalPrice - 8*numOfPeople}€  {t('submit.12')}</p>
                                <p>{8*numOfPeople}€ {t('submit.13')}</p>
                              </div>
                            )}</p>
                        </div>
                    </div>
                    <div className='col-6 col-lg-auto d-flex flex-column '>
                        <h5 className='mb-1'>{t('submit.5')}</h5>
                        <div>
                        <p >  {t('submit.6')} {name}</p>
                        <p> {t('submit.7')} {tel}</p>
                        <p>  email: {email}</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr className='my-2'></hr>
            <p className='align-self-center basicP'>{t('submit.8')}</p>
            <div className='col-12 d-flex flex-column flex-lg-row justify-content-center gap-2 gap-lg-5 mt-2'>
                <div className='d-flex'> <img src={telImg} className='mx-2' alt='kayak tel' /> <p>{t('submit.7')} </p> <a className='subLinks' href='tel:+30 694 052 4341'> 00306940524341</a></div>
                <div className='d-flex'><img src={mailImg} className='mx-2'alt='kayak email'/> <p>Email: </p> <a className='subLinks' href="mailto:dragonflykayak@yahoo.com">dragonflykayak@yahoo.com </a></div>
            </div>
            <p className='align-self-end mt-3 basicP'>{t('submit.9')}</p>
            <Link className='homeLink mt-3' to={'/'}>{t('submit.11')}</Link>
        </div>
        </div>
    );
}

export default SubmitPage;
