import React from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
//import bootstrap
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./pickupPage.css"
//import components
import Header from './header.js';
import Footer from './footer.js';
import PickupScedule from './pickUpScedule.js';
import PickupCard from './pickupCard.js';
//import images
import pickupImg from './../images/littleAcheron/pickupImg.webp'




function PickupPage() {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, []);

   
    return (
        <div>
            <Helmet>
        <meta name="description" content="Create lasting holiday memories with Dragonfly Kayak's 4-hour Odyssey tour on the Acheron River. Perfect for couples and families with older kids, enjoy breathtaking scenery and an unforgettable kayaking adventure in Parga. Book your Odyssey tour today!" />
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WC3GL5Z');
          `}
        </script>
      </Helmet>
            <Header/>
            <div className='col-12 imgScheduleHolder'>
                <img src={pickupImg} className='scheduleHeroImg' alt='pickUp Parga for Kayak'/>
                <PickupCard/>
                <PickupScedule/>
            </div>
            
            <Footer/>
        </div>
      );
}

export default PickupPage;