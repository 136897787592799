import Carousel from 'react-bootstrap/Carousel';
import Irf from "./../images/IrfImage.png"
import Rescue3 from "./../images/Rescue3Icon.jpg"
import Wilderness from "./../images/WildernessIcon.png"
import "./myCarousel.css"

function MyCarousel() {
    const slides = [
      {
        image: `${Irf}`,
        alt: 'Whitewater guide category A',
        caption: 'International Rafting Federation Swiftwater.'
      },
      {
        image: `${Rescue3}`,
        alt: 'Flood rescue technician',
        caption: 'Rescue3 Europe/Rescue3 International.'
      },
      {
        image: `${Wilderness}`,
        alt: 'Wilderness First Aid',
        caption: 'ECSI - AAOS.'
      },
    ];
  
    return (
      <Carousel className='carousel'>
        {slides.map((slide) => (
          <Carousel.Item key={slide.alt}>
            <img
              src={slide.image}
              alt={slide.alt}
            />
            <Carousel.Caption>
              <h3>{slide.alt}</h3>
              <p>{slide.caption}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }

  export default MyCarousel;