import React from 'react';
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//import images
import odysseyImg from './../images/odyssey/OddysseyPhoto.webp'
import img1 from './../images/odyssey/img1.webp'
import img2 from './../images/odyssey/img2.webp'
import img3 from './../images/odyssey/img3.webp'
import img4 from './../images/odyssey/img4.webp'
import img5 from './../images/odyssey/img5.webp'
import img6 from './../images/odyssey/img6.webp'
import img7 from './../images/odyssey/img7.webp'
import img8 from './../images/odyssey/img8.webp'
import img9 from './../images/odyssey/img9.webp'
import img10 from './../images/odyssey/img10.webp'
import img11 from './../images/odyssey/img11.webp'
import img12 from './../images/odyssey/img12.webp'
import img13 from './../images/odyssey/img13.webp'
import img14 from './../images/odyssey/img14.webp'
import Logo from './../images/BlueLogo.png'
import vestImg from './../images/tours-icons/vest.svg'
import kayakImg from './../images/tours-icons/kayak.svg'
import waterImg from './../images/tours-icons/water_bottle.svg'
import dropletImg from './../images/tours-icons/droplet-half.svg'
import parkingImg from './../images/tours-icons/parking.svg'
import busImg from './../images/tours-icons/bus.svg'
//import components
import Header from './header.js';
import Footer from './footer.js';
import ToursCarousel from './toursCarousel.js';
import "./tourOdyssey.css";






function TourOdyssey() {
  const {t} = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const odysseyImgs =[img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14];
  const hours = ['15:00'];
    //for the button to be sticky
    const [isSticky, setIsSticky] = useState(false);
    const buttonRef = useRef(null);
  
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const buttonHeight = buttonRef.current ? buttonRef.current.offsetHeight : 0; // Get button height
      const windowHeight = window.innerHeight;
      setIsSticky(scrollY + windowHeight  >= buttonRef.current.offsetTop + buttonHeight);
    };
  
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []); // Empty dependency array to run effect only once
    ////for the button to be sticky end/////////

    ////////to go to the booking form///////////
    const handleNext = () => {
     // navigate('/Booking-form', { state: { toursTitle:t("Odyssey.title") , hours:hours } }); 
      navigate('/booking-First-Step', { state: { toursTitle:t("Odyssey.title") , hours:hours, tourNum:'2' } }); 
    };



      // keep data for the booking page
    const navigate = useNavigate();



    ////////to go to the booking form end//////////
  
    //
  return (
    
      <div className="App">
        <Helmet>
        <title>Dragonfly Kayak: Odyssey</title>
        <meta name="description" content="Create lasting holiday memories with Dragonfly Kayak's 4-hour Odyssey tour on the Acheron River. Perfect for couples and families with older kids, enjoy breathtaking scenery and an unforgettable kayaking adventure in Parga. Book your Odyssey tour today!" />
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WC3GL5Z');
          `}
        </script>
      </Helmet>
          <Header/>
            <section id='tour-odyssey-hero'>
              <div className='toursHeroOdyssey'>
                <img src={odysseyImg} alt='Parga kayak'/>
              </div>
            </section>
            <div className='d-flex flex-column col-12 col-lg-auto align-items-center tourPageDetails my-4 mx-auto'>
              <div className='d-flex col-12 col-lg-6 align-items-center justify-content-lg-start justify-content-center tourPageDetails'>
                <div>
                  <img src={Logo} alt='Parga kayak' />
                </div>
                <div className='d-flex flex-column '>
                    <h2>{t("Odyssey.title")}</h2>
                    <p>{t("Odyssey.subTitle")}</p>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row col-12 col-lg-6 justify-content-start justify-content-lg-start align-items-center'>
                <div className='d-flex flex-column col-12 col-lg-auto px-4 borderDiv align-items-center align-items-lg-start'>
                
                  <div className='spanPHolder'>
                    <span>
                    {t("Odyssey.price")}
                    </span>
                    <div className='d-flex flex-row priceDiv'>
                      <p>50€</p>
                      
                    </div>
                  </div>
                  <div>
                    <span>
                    {t("Odyssey.ToursEveryDayAt")}
                    </span>
                    <div className='d-flex flex-row priceDiv'>
                      
                      <p>15:00 </p>
                     
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column px-4 gap-3 col-12 col-lg-auto align-items-center align-items-lg-start'>
                  <div className='d-flex flex-row gap-5 col-6 col-lg-auto justify-content-between'>
                    <div className='d-flex flex-column'>
                      <span>{t("Odyssey.Duration")}</span>
                      <p>3-4 Hour</p>
                    </div>
                    <div className='d-flex flex-column'>
                      <span>{t("Odyssey.Ages")}</span>
                      <p>{t("Odyssey.all")}</p>
                    </div>
                  </div>
                  <div className='d-flex flex-row gap-5 col-6 col-lg-auto justify-content-between'>
                    <div className='d-flex flex-column'>
                      <span>{t("Odyssey.Difficulty")}</span>
                      <p>1/6</p>
                    </div>
                    <div className='d-flex flex-column'>
                      <span>{t("Odyssey.Distance")}</span>
                      <p>14 km</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 d-flex flex-row paraNav'>
              <a href='#synopsi'>{t("Odyssey.subNav.Summary")}</a>
              <a href='#iportDet'>{t("Odyssey.subNav.importantInformation")}</a>
              <a href='#faq'>{t("Odyssey.subNav.FAQs")}</a>
              <button onClick={handleNext}>{t("Odyssey.subNav.bookNow")}</button>
            </div>
            <div  id="my-button" ref={buttonRef} className={isSticky ? "sticky" : "bookNowStickyDiv d-flex flex-row justify-content-center align-items-center"}>
              <button onClick={handleNext}>{t("Odyssey.subNav.bookNow")}</button>
            </div>
            <section id="synopsi">
              <div className='col-12 d-flex flex-column py-5 align-items-center'>
                <div className='col-12 col-md-10 col-xl-7 px-2 px-lg-0  d-flex- flex-column align-items-center'>
                  <h3>{t("Odyssey.subNav.Summary")}</h3>
                  <div className=' col-12 d-flex flex-column flex-lg-row gap-3 gap-lg-0 justify-content-between'>
                    <div className='articleDiv col-12 col-lg-6'>
                      <article style={{ whiteSpace: 'pre-line' }}>
                      {t("Odyssey.summaryText")}
                      </article>
                    </div>
                    <div className='d-flex flex-column col-12 col-lg-6 px-lg-3 rightDiv justify-content-start align-items-lg-center'>
                      <h5>{t("Odyssey.Allowances.title")}</h5>
                      <ul className='innerUL1'>
                        <li>
                          <img className='benefitsIcons' src={vestImg} alt='Parga kayak' />
                          {t("Odyssey.Allowances.first")}
                        </li>
                        <li>
                          <img className='benefitsIcons' src={kayakImg} alt='Parga kayak' />
                          {t("Odyssey.Allowances.secont")}</li>
                        <li>
                          <img className='benefitsIcons' src={waterImg} alt='Parga kayak' />
                          {t("Odyssey.Allowances.third")}
                        </li>
                        <li>
                          <img className='benefitsIcons' src={dropletImg} alt='Parga kayak' />
                          {t("Odyssey.Allowances.fourth")}
                        </li>
                        <li>
                          <img className='benefitsIcons' src={parkingImg} alt='Parga kayak' />
                          {t("Odyssey.Allowances.fifth")}
                        </li>
                        <li>
                          <img className='benefitsIcons' src={busImg} alt='Parga kayak' />
                          {t("Odyssey.Allowances.sixth")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id='iportDet'>
              <div className='col-12 d-flex flex-column py-5 align-items-center'>
                <div className='col-12 col-md-10 col-xl-7 px-2 px-lg-0 d-flex- flex-column align-items-center'>
                  <h3>{t("Odyssey.subNav.importantInformation")}</h3>
                  <div className=' col-12 d-flex flex-column flex-lg-row gap-3 gap-lg-0 justify-content-between'>
                    <div className='articleDiv col-12 col-lg-6'>
                      <li>
                        <span>{t("Odyssey.impInfo.miniFirst")}</span>
                        <p>{t("Odyssey.impInfo.first")}</p>
                      </li>
                      <li>
                        <span>{t("Odyssey.impInfo.miniSecont")}</span>
                        <p>{t("Odyssey.impInfo.secont")}</p>
                      </li>
                      <li>
                        <span>{t("Odyssey.impInfo.miniThird")}</span>
                        <p>{t("Odyssey.impInfo.third")}</p>
                      </li>
                      <li>
                        <span>{t("Odyssey.impInfo.miniFourth")}</span>
                        <p>{t("Odyssey.impInfo.fourth")}</p>
                      </li>
                      <li>
                        <span>{t("Odyssey.impInfo.miniFifth")}</span>
                        <p>{t("Odyssey.impInfo.fifth")}</p>
                      </li>
                    </div>
                    <div className='d-flex flex-column col-12 col-lg-6 px-lg-3 rightDiv justify-content-start align-items-lg-center'>
                      <h5>{t("Odyssey.impInfo.need")}</h5>
                      <ul className='innerUl'>
                        <li>{t("Odyssey.impInfo.Swimsuit")}</li>
                        <li>{t("Odyssey.impInfo.Hat")}</li>
                        <li>{t("Odyssey.impInfo.T-shirt")}</li>
                        <li>{t("Odyssey.impInfo.Camera")}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id='faq'>
              <div className='col-12 d-flex flex-column justify-content-center align-items-center mb-5'>
              
                <div className='col-12 col-md-10 col-xl-7 px-2 px-lg-0 d-flex flex-column articleDiv last gap-3'>
                <h3>FAQs</h3>
                  <li>
                    <span>{t("Odyssey.faqFirst")}</span>
                    <p>{t("Odyssey.faqFirstAns")}</p>
                  </li>
                  <li>
                    <span>{t("Odyssey.faqSecont")}</span>
                    <p>{t("Odyssey.faqSecontAns")}</p>
                  </li>
                </div>
                <div className='col-12 tourCarouselHolder mt-5 mb-3'>
                    <ToursCarousel images={odysseyImgs} numImages={odysseyImgs.length}/>
              </div>
              </div>
            </section>
          <Footer/>  
      </div>
  );
}

export default TourOdyssey;