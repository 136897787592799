import React from 'react';
import { useTranslation } from 'react-i18next';
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./../../node_modules/bootstrap/dist/js/bootstrap.bundle.js"
import "./aboutUs.css"
import Apollo from "./../images/ApolloImage.png"
import Esiness from "./../images/esinessImage1.png"
import Compass from "./../images/CompassImage.png"

import MyCarousel from './myCarousel.js';

import Irf from "./../images/IrfImage.png"
import Rescue3 from "./../images/Rescue3Icon.jpg"
import Wilderness from "./../images/WildernessIcon.png"



function AboutUs() {
  const {t} = useTranslation();
  return (
    <div className='col-12 py-5  AboutUsSection mt-0'>
        <div className='col-12  d-flex flex-column flex-lg-row h-75 mb-5 justify-content-center align-items-center align-items-lg-stretch'>
        <div className='col-11 col-lg-5 aboutUsText'>
            <h3 className='aboutUsTitle mb-4'>{t("landingPage.AboutUs.title")}</h3>
            <p>{t("landingPage.AboutUs.mainText")}</p>
        </div>
        <div className='col-12 col-lg-4 partners d-flex flex-column justify-content-start'>
            <h4>{t("landingPage.Partners.title")}</h4>
            <div className='d-flex flex-row flex-lg-column justify-content-center align-items-lg-center image-holder'>

                <img className='partnersImages apolloImage'  src={Apollo}  alt='Apollo Parga image'/>
                <img className='esinessImage partnersImages' src={Esiness} alt='Esiness Parga image'/>
                <img className='partnersImages compassImage' src={Compass} alt='Parga Outdoor activities'/>
            </div>  
        </div>
        </div>
        <div className='col-12 col-lg-10 certifications bg-white'>
          <h2>{t("landingPage.AboutUs.Certifications")}</h2>
          <div className='col-12 row justify-content-around'>
            <div className='col-3'>
              <img className='irfImage'src={Irf} alt='kayak cert'/>
               <h3>{t("landingPage.AboutUs.firstCertH")}<p> {t("landingPage.AboutUs.firstCertp")}</p></h3>
            </div>
            <div className='col-3'>
              <img src={Rescue3} alt='kayak cert'/>
                 <h3>{t("landingPage.AboutUs.secontCertH")}<p>{t("landingPage.AboutUs.secontCertp")}</p></h3>
            </div>
            <div className='col-3' alt='kayak cert'>
              <img className='wildernessImage' src={Wilderness}/>
                 <h3>{t("landingPage.AboutUs.thirdCertH")}<p>{t("landingPage.AboutUs.thirdCertp")}</p></h3>
            </div>
          </div>
        </div>
        <MyCarousel/>
    </div>
  );
}

export default AboutUs;

