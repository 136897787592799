import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import { ElfsightWidget } from 'react-elfsight-widget';
import { Helmet } from 'react-helmet';
import React from 'react';
//import bootstrap
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css"
//import components
import Header from './header.js';
import Footer from './footer.js';
import Hero from './hero.js';
import Cards from './cards.js';
import AboutUs from './aboutUs.js';
import MyPhotosAndVideos from './instagram.js';
import "./landingPage.css";
//import images
import stepsImg from './../images/drag-map.png'
import logo from './../images/BlueLogo.svg'








function LandingPage() {
  const {t} = useTranslation();


  const schemaData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Dragonfly Kayak",
    "description": "Unforgettable kayaking adventures on the Acheron River in Parga, Greece.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Ammoudia 480 62",
      "addressLocality": "Parga",
      "addressRegion": "Preveza",
      "postalCode": "480 62",
      "addressCountry": "GR" // Greece
    },
    "telephone": "+30 6940524341",
    "image": {logo}, // Replace with your image URL
    "url": "https://www.dragonfly-kayak.com/" // Your website URL
  };


  const widgetID = 'd86b4442-82ce-441e-8463-fd1e558f60a7';

  const AEHours=['9:30','14:00','18:00'];
  const LAHours=['10:00','12:00','16:00','18:30'];
  const OHours=['15:00'];
  return (

    <div className="mainPage">
      <Helmet>
        <title>Dragonfly Kayak: The Acheron Experiences</title>
        <meta name="description" content="Experience the thrill of kayaking on the Acheron River with Dragonfly Kayak in Parga. Book your kayak tour in Acheron today!" />
                
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WC3GL5Z');
          `}
        </script>
        
      </Helmet>
        <Header/>
        <section id='Hero'>
          <Hero/>
        </section>
        <div className='cardsDiv col-12 mb-3'>
          <div className='row cardsHolder col-11 col-lg-10 col-xl-12 justify-space-around align-items-center d-flex flex-column flex-xl-row'>
            <h2 className="cardsH2">{t('landingPage.subTitleF')} <p className='toursWord'>{t('landingPage.subTitleS')}</p></h2>
            <Cards className="firstCrd" tourNum='1'  navLink='/booking-First-Step' title={t('landingPage.cards.fTitle')} link='/Acheron-Escape-kayak-Tour#tour-Acheron-escape-hero' hours={AEHours} />
            <Cards className='secondCrd' tourNum='2'  navLink='/booking-First-Step' title={t('landingPage.cards.sTitle')} link='/Odyssey-kayak-tour#tour-odyssey-hero' hours={OHours}/>
            <Cards className= "thirdCrd" tourNum='3'  navLink='/Booking-form'  title={t('landingPage.cards.tTitle')} link='/Litle-Acheron-kayak-tour#tour-little-Acheron-hero' hours={LAHours}/> 
            <div className='col-12 col-lg-9 steps'>
              <h3 className='carText'>
                No car ? <br></br><span>No problem!</span>
              </h3>
              <img src={stepsImg} alt='dragonfly kayak parga syvota kanali transfer'></img>
              <div className='col-12 d-flex flex-column flex-lg-row justify-content-between px-0 gap-4 gap-lg-2'>
                <li className='col-12 col-lg-3'>{t('landingPage.pickUpFirst')}</li>
                <li className='col-12 col-lg-3'>{t('landingPage.pickUpSecont')}</li>
                <li className='col-12 col-lg-3'>{t('landingPage.pickUpThird')}</li>
              </div>
              <button className='mt-3'>
                <Link to='/Pickup-Schedule'>
                {t('landingPage.pickUpButt')}
                  <div></div>
                </Link>
             </button>
            </div>
          </div>
        </div>
        <AboutUs/>
        
        <MyPhotosAndVideos/>
        <ElfsightWidget widgetId={widgetID} styles={{ width: '100% !important', marginTop: '10%' }} />
        <iframe className='map' title='Dragonfly kayak Place' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d198167.76898909852!2d20.4708849!3d39.098014!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135c6539aeb94485%3A0xe0e3e9167d0304df!2sDragonfly%20Kayak%3A%20The%20Acheron%20Experience!5e0!3m2!1sen!2sgr!4v1714652145761!5m2!1sen!2sgr" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        <Footer/>  
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
    </div>
  );
}

export default LandingPage;