import React from 'react';
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
//import images
import ExtImg from './../images/acheronEscape/ExtendedTourImage.webp'
import img1 from './../images/acheronEscape/img1.webp'
import img2 from './../images/acheronEscape/img2.webp'
import img3 from './../images/acheronEscape/img3.webp'
import img4 from './../images/acheronEscape/img4.webp'
import img5 from './../images/acheronEscape/img5.webp'
import img6 from './../images/acheronEscape/img6.webp'
import img7 from './../images/acheronEscape/img7.webp'
import img8 from './../images/acheronEscape/img8.webp'
import img9 from './../images/acheronEscape/img9.webp'
import img10 from './../images/acheronEscape/img10.webp'
import img11 from './../images/acheronEscape/img11.webp'
import img12 from './../images/acheronEscape/img12.webp'
import Logo from './../images/BlueLogo.png'
import vestImg from './../images/tours-icons/vest.svg'
import kayakImg from './../images/tours-icons/kayak.svg'
import waterImg from './../images/tours-icons/water_bottle.svg'
import dropletImg from './../images/tours-icons/droplet-half.svg'
import parkingImg from './../images/tours-icons/parking.svg'
import busImg from './../images/tours-icons/bus.svg'
//import components
import Header from './header.js';
import Footer from './footer.js';
import ToursCarousel from './toursCarousel.js';
import "./tourAcheronEscape.css";





function TourAcheronEscape() {
  const {t} = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  
  const AEImgs =[img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,];
  const hours = ['9:30','14:00','18:00'];

    //for the button to be sticky
    const [isSticky, setIsSticky] = useState(false);
    const buttonRef = useRef(null);
  
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const buttonHeight = buttonRef.current ? buttonRef.current.offsetHeight : 0; // Get button height
      const windowHeight = window.innerHeight;
      setIsSticky(scrollY + windowHeight  >= buttonRef.current.offsetTop + buttonHeight);
    };
  
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []); // Empty dependency array to run effect only once
    

      ////////to go to the booking form///////////
  const handleNext = () => {
    navigate('/booking-First-Step', { state: { toursTitle:t("AcheronEscape.title") , hours:hours, tourNum:'1' } }); 
  };



    // keep data for the booking page
  const navigate = useNavigate();



  ////////to go to the booking form end//////////
  
    //
  return (
    
      <div className="App">
          <Helmet>
        <title>Dragonfly Kayak: Acheron Escape</title>
        <meta name="description" content="Embark on an unforgettable adventure with Dragonfly Kayak's Acheron Escape tour. Kayak the Acheron River, and paddle in mythical waters. Book your Acheron Escape in Parga today!" />

        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WC3GL5Z');
          `}
        </script>
      </Helmet>
          <Header/>
          <section id='tour-Acheron-escape-hero'>
            <div className='toursHero'>
              <img src={ExtImg} alt='Parga kayak'/>
            </div>
            </section>
            <div className='d-flex flex-column col-12 col-lg-auto align-items-center tourPageDetails my-4'>
              <div className='d-flex col-12 col-lg-6 align-items-center justify-content-lg-start justify-content-center tourPageDetails'>
                <div>
                  <img src={Logo} alt='Parga kayak' />
                </div>
                <div className='d-flex flex-column '>
                    <h2>{t("AcheronEscape.title")}</h2>
                    <p>{t("AcheronEscape.subTitle")}</p>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row col-12 col-lg-6 justify-content-start justify-content-lg-start align-items-center'>
                <div className='d-flex flex-column col-12 col-lg-auto px-4 borderDiv align-items-center align-items-lg-start'>
                
                  <div className='spanPHolder'>
                    <span>
                    {t("AcheronEscape.price")}
                    </span>
                    <div className='d-flex flex-row priceDiv'>
                      <p>0-6 {t("AcheronEscape.yo")}  7€</p>
                      <p> 6-12 {t("AcheronEscape.yo")}  12€</p>
                      <p>12+ {t("AcheronEscape.yo")} 25€</p>
                    </div>
                  </div>
                  <div>
                    <span>
                    {t("AcheronEscape.ToursEveryDayAt")}
                    </span>
                    <div className='d-flex flex-row priceDiv'>
                      <p>09:30</p>
                      <p>14:00 </p>
                      <p>18:00</p>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column px-4 gap-3 col-12 col-lg-auto align-items-center align-items-lg-start'>
                  <div className='d-flex flex-row gap-5 col-6 col-lg-auto justify-content-between'>
                    <div className='d-flex flex-column'>
                      <span>{t("AcheronEscape.Duration")}</span>
                      <p>1:30 {t('bookNowpage.1.1')}</p>
                    </div>
                    <div className='d-flex flex-column'>
                      <span>{t("AcheronEscape.Ages")}</span>
                      <p>{t("AcheronEscape.all")}</p>
                    </div>
                  </div>
                  <div className='d-flex flex-row gap-5 col-6 col-lg-auto justify-content-between'>
                    <div className='d-flex flex-column'>
                      <span style={{ whiteSpace: 'pre-line' }}>{t("AcheronEscape.Difficulty")}</span>
                      <p>1/6</p>
                    </div>
                    <div className='d-flex flex-column'>
                      <span>{t("AcheronEscape.Distance")}</span>
                      <p>5.5km</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 d-flex flex-row paraNav'>
              <a href='#synopsi'>{t("AcheronEscape.subNav.Summary")}</a>
              <a href='#iportDet'>{t("AcheronEscape.subNav.importantInformation")}</a>
              <a href='#faq'>{t("AcheronEscape.subNav.FAQs")}</a>
              <button onClick={handleNext}>{t("Odyssey.subNav.bookNow")}</button>
            </div>
            <div  id="my-button" ref={buttonRef} className={isSticky ? "sticky" : "bookNowStickyDiv d-flex flex-row justify-content-center align-items-center"}>
            <button onClick={handleNext}>{t("Odyssey.subNav.bookNow")}</button>
            </div>
            <section id="synopsi">
              <div className='col-12 d-flex flex-column py-5 align-items-center'>
                <div className='col-12 col-md-10 col-xl-7 px-2 px-lg-0  d-flex- flex-column align-items-center'>
                  <h3>{t("AcheronEscape.subNav.Summary")}</h3>
                  <div className=' col-12 d-flex flex-column flex-lg-row gap-3 gap-lg-0 justify-content-between'>
                    <div className='articleDiv col-12 col-lg-6'>
                      <article style={{ whiteSpace: 'pre-line' }}>
                      {t("AcheronEscape.summaryText")}
                      </article>
                    </div>
                    <div className='d-flex flex-column col-12 col-lg-6 px-lg-3 rightDiv justify-content-start align-items-lg-center'>
                      <h5>{t("AcheronEscape.Allowances.title")}</h5>
                      <ul className='innerUL1'>
                        <li>
                          <img className='benefitsIcons' src={vestImg} alt='Parga kayak' />
                          {t("AcheronEscape.Allowances.first")}
                        </li>
                        <li>
                          <img className='benefitsIcons' src={kayakImg} alt='Parga kayak' />
                          {t("AcheronEscape.Allowances.secont")}</li>
                        <li>
                          <img className='benefitsIcons' src={waterImg} alt='Parga kayak' />
                          {t("AcheronEscape.Allowances.third")}
                        </li>
                        <li>
                          <img className='benefitsIcons' src={dropletImg} alt='Parga kayak' />
                          {t("AcheronEscape.Allowances.fourth")}
                        </li>
                        <li>
                          <img className='benefitsIcons' src={parkingImg} alt='Parga kayak' />
                          {t("AcheronEscape.Allowances.fifth")}
                        </li>
                        <li>
                          <img className='benefitsIcons' src={busImg} alt='Parga kayak' />
                          {t("AcheronEscape.Allowances.sixth")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id='iportDet'>
              <div className='col-12 d-flex flex-column py-5 align-items-center'>
                <div className='col-12 col-md-10 col-xl-7 px-2 px-lg-0 d-flex- flex-column align-items-center'>
                  <h3>{t("AcheronEscape.subNav.importantInformation")}</h3>
                  <div className=' col-12 d-flex flex-column flex-lg-row gap-3 gap-lg-0 justify-content-between'>
                    <div className='articleDiv col-12 col-lg-6'>
                      <li>
                        <span>{t("AcheronEscape.impInfo.miniFirst")}</span>
                        <p>{t("AcheronEscape.impInfo.first")}</p>
                      </li>
                      <li>
                        <span>{t("AcheronEscape.impInfo.miniSecont")}</span>
                        <p>{t("AcheronEscape.impInfo.secont")}</p>
                      </li>
                      <li>
                        <span>{t("AcheronEscape.impInfo.miniThird")}</span>
                        <p>{t("AcheronEscape.impInfo.third")}</p>
                      </li>
                      <li>
                        <span>{t("AcheronEscape.impInfo.miniFourth")}</span>
                        <p>{t("AcheronEscape.impInfo.fourth")}</p>
                      </li>
                      <li>
                        <span>{t("AcheronEscape.impInfo.miniFifth")}</span>
                        <p>{t("AcheronEscape.impInfo.fifth")}</p>
                      </li>
                    </div>
                    <div className='d-flex flex-column col-12 col-lg-6 px-lg-3 rightDiv justify-content-start align-items-lg-center'>
                      <h5>{t("AcheronEscape.impInfo.need")}</h5>
                      <ul className='innerUl'>
                        <li>{t("AcheronEscape.impInfo.Swimsuit")}</li>
                        <li>{t("AcheronEscape.impInfo.Hat")}</li>
                        <li>{t("AcheronEscape.impInfo.T-shirt")}</li>
                        <li>{t("AcheronEscape.impInfo.Camera")}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id='faq'>
              <div className='col-12 d-flex flex-column justify-content-center align-items-center mb-5'>
              
                <div className='col-12 col-md-10 col-xl-7 px-2 px-lg-0 d-flex flex-column articleDiv last gap-3'>
                <h3>FAQs</h3>
                  <li>
                    <span>{t("AcheronEscape.faqFirst")}</span>
                    <p>{t("AcheronEscape.faqFirstAns")}</p>
                  </li>
                  <li>
                    <span>{t("AcheronEscape.faqSecont")}</span>
                    <p>{t("AcheronEscape.faqSecontAns")}</p>
                  </li>
                </div>
                <div className='col-12 tourCarouselHolder mt-5 mb-3'>
                <ToursCarousel images={AEImgs} numImages={AEImgs.length}/>
              </div>
              </div>
            </section>
          <Footer/>  
      </div>
  );
}

export default TourAcheronEscape;