import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import "./footer.css"
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./../../node_modules/bootstrap/dist/js/bootstrap.bundle.js"
import instaIcon from "./../images/instagram.svg"
import fbIcon from "./../images/facebook.svg"
import googleIcon from "./../images/google.svg"
import whatsAppIcon from "./../images/whatsapp.svg"

const Footer = () => {
  const {t} = useTranslation();
  return (
    <footer className="bg-primary text-white">
      <div className='col-12 d-flex flex-column '>
        <div className='col-12 d-flex px-3 py-4 px-lg-2 gap-3 gap-lg-0 flex-column flex-lg-row footerInnerDiv'>
          <div className='col-8 col-lg-3 gap-2 d-flex flex-column align-items-start align-items-lg-center'>
            <h5>{t("footer.contact")}</h5>
            <a className='footLink' href="mailto:dragonflykayak@yahoo.com">dragonflykayak@yahoo.com</a>
            <a className='footLink' href="tel:+30 694 052 4341">+306940524341</a>
            <a className='footLink' href="https://maps.app.goo.gl/qEvTAEbcwdBnHTyn9">Ammoudia 0, Preveza, Greece, 48062</a>
          </div>
          <div className='col-8 col-lg-3 gap-2 d-flex flex-column align-items-start align-items-lg-center'>
            <h5>FAQ</h5>
            <Link className='footLink' to="/Acheron-Escape-kayak-Tour#faqs">{t("Odyssey.faqFirst")}</Link>
            <Link className='footLink' to="/Acheron-Escape-kayak-Tour#faqs">{t("Odyssey.faqSecont")}</Link>
          </div>
          <div className='col-8 col-lg-3 gap-2 d-flex flex-column align-items-start align-items-lg-center'>
            <h5>{t("footer.leaveRev")}</h5>
            <a className='footLink' href="https://g.page/r/Cd8EA30W6ePgEBM/review">{t("footer.Google")}</a>
            <a className='footLink' href="https://www.tripadvisor.com/Attraction_Review-g1188698-d23947493-Reviews-Dragonfly_Kayak_The_Acheron_Experience-Ammoudia_Preveza_Region_Epirus.html">{t("footer.tripAdv")}</a>
          </div>
          <div className='col-8 col-lg-3 gap-2 d-flex flex-column align-items-start align-items-lg-center'>
            <h5>{t("footer.simAct")}</h5>
            <a className='footLink' href="#">Hiking Parga</a>
            <a className='footLink' href="#">e-kayaking</a>
            <a className='footLink' href="https://horse-riding-alonaki.jimdofree.com/">Horseriding Alonaki</a>
          </div>
        </div>

        <div className='lowest-part col-12 d-flex flex-column align-items-center py-4'>
          <div className='col-12 col-lg-8 d-flex flex-row justify-content-center'>
            <a href='https://www.instagram.com/dragonfly_kayak/'><img className='social' src={instaIcon} alt='kayak in Acheron Instagram'></img></a>
            <a href='https://www.facebook.com/kayakdragonfly'><img className='social' src={fbIcon} alt='kayak in Acheron Facebook'></img></a>
            <a href='https://maps.app.goo.gl/HJJnbko9KtYwKJXh9'><img className='social' src={googleIcon} alt='kayak in Acheron Google'></img></a>
            <a href='https://wa.me/+306940524341'><img className='social' src={whatsAppIcon} alt='kayak in Acheron tel'></img></a>
          </div>
          <hr></hr>
          <p className='copyrights'>2024 Dragonfly Kayak. All right reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

