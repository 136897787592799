import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';

import en from '../Langs/en.json'; // Import English translations correctly
import gr from '../Langs/gr.json'; // Import Greek translations correctly


const resources = {
  en: {
    translations: en, // Define translations under the 'translations' namespace
  },
  gr: {
    translations: gr, // Define translations under the 'translations' namespace
  },
};
i18n
  // ... other options
  .use(initReactI18next) // Initializes i18next for React
  .init({
    fallbackLng: 'en', // Set English as the fallback language
    resources,
    defaultNS: 'translations', // Set the default namespace
    //keySeparator: false, // Optional: disable key separator
    interpolation: {
      escapeValue: false, // Optional: prevent escaping of HTML entities (use with caution)
    },
  });

  
export default i18n;