import React from 'react';
import "./pickupCard.css";
import { useTranslation } from 'react-i18next';
// import images
import logo from './../images/BlueLogo.svg'


const PickupCard = () => {
    const {t} = useTranslation();
    return (
        <div className='col-md-8 col-xxl-6 col-12 mx-auto cardDiv'>
            <div className='col-12 d-flex flex-row px-md-5 px-2 py-4 titleHolder'>
                <div className='logoImg'>
                    <img src={logo} alt="Dragonfly kayak in Acheron logo"/>
                </div>
                <div className='d-flex flex-column justify-content-center'>
                    <p>{t("pickUpCard.firstP")}</p>
                    <p>{t("pickUpCard.secP")}</p>
                </div>
            </div>
            <hr className='cardHr'></hr>
            <div className='col-12 d-flex flex-column gap-2 px-md-5 px-2 pt-4 cardDet'>
                <p>{t("pickUpCard.thirdP")}</p>
                <p className='boldLi'> {t("pickUpCard.cost")}</p>
                <p>{t("pickUpCard.fourthP")}</p>
                <ul>
                    <li>{t("pickUpCard.firstLi")}</li>
                    <li>{t("pickUpCard.secLi")}</li>
                    <li>{t("pickUpCard.thirdLi")}</li>
                </ul>
            </div>
        </div>
    );
};




export default PickupCard;

