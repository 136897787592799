import React from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from 'react-helmet'; // Import React Helmet
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./../../node_modules/bootstrap/dist/js/bootstrap.bundle.js"
import "./hero.css" 
import heroImg from './../images/heroImg.webp';



  

const Hero = () => {
    const {t} = useTranslation();
    const h1Text = 'Dragonfly Kayak: The Acheron Experience'; // Combine h1 parts
    return (
    <div className='hero-div col-12'>
      <Helmet>
        <title>{h1Text}</title>  {/* Set title using combined h1 text */}
        <meta name="description" content="Experience the thrill of kayaking on the Acheron River with Dragonfly Kayak in Parga. Book your kayak tour today!" />
        <link rel="canonical" href='https://www.dragonfly-kayak.com/' />
      </Helmet>
        <h1>{t("landingPage.hFirst")} <p className='brandName px-1'>{t("landingPage.hSecont")}</p></h1>
        <Link to='/Kayak-Tour-Book'>
            <a className="bookButt">
                <div></div>
            {t("landingPage.bookNowButt")}
            </a>
        </Link>
        <img src={heroImg} alt='Kayak in Acheron - Parga' className="mobile-photo" />
    </div>
    );
};
  export default Hero;
