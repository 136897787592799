import { useTranslation } from 'react-i18next';
import React, { useState }  from 'react';
//import bootstrap
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css"
//import components
import "./pickupScedule.css";









function PickupScedule() {
  const {t} = useTranslation();

  const [list1Style, setList1Style] = useState({ display: 'block' });
  const [list2Style, setList2Style] = useState({ display: 'none' });
  const [butt1, setButt1] = useState(true);
  const [butt2, setButt2] = useState(false);
  

  const handleList1 = () => {
    setList1Style({ display: 'block' });
    setList2Style({ display: 'none' });
    setButt1(true);
    setButt2(false);
  };

  const handleList2 = () => {
    setList1Style({ display: 'none' });
    setList2Style({ display: 'block' });
    setButt1(false);
    setButt2(true);
  };

  return (  
   
    
    <div className="pickupMainDiv col-md-8 col-xxl-6 col-12 d-flex flex-column  align-items-center ">
            
        <div className='col-12 gap-5 py-5 mx-auto d-flex flex-column pickUpHolder align-items-center'>
            <div className='col-11 d-flex align-items-end toursButtHolder'>
                <button className={butt1 ? 'active-button' : 'inactive-button'} onClick={handleList1}>Acheron Escape</button>
                <button className={butt2 ? 'active-button' : 'inactive-button'} onClick={handleList2}>Odyssey</button>
            </div>
            <div className='col-12 col-lg-11 '>
                <div className='col-10 mx-auto'>
                    <h2>{t("pickUpSch.parga")}</h2>
                    <h3 style={list1Style}>{t("pickUpSch.days.tuesday")}</h3>
                    <h3 style={list2Style}>{t("pickUpSch.days.wednestay")}</h3>
                </div>
                <div className='col-10 col-lg-9 d-flex flex-row justify-content-between mx-auto'>
                    <p>{t("pickUpSch.pickupPoint")}</p>
                    <p>{t("pickUpSch.pickupTime")}</p>
                </div>
                <div className='col-10 col-lg-9 d-flex flex-row justify-content-between mx-auto'>
                    <ul>
                        <a href='https://maps.app.goo.gl/YNhshwSzu1MZu3X46' className='placesLink'><li>{t("pickUpSch.placesParga.1")}</li></a>
                        <a href='https://maps.app.goo.gl/QYqMzpSRwaJdJb968' className='placesLink'><li>{t("pickUpSch.placesParga.2")}</li></a>
                        <a href='https://maps.app.goo.gl/6YGCTdhoL3mQPKHF9' className='placesLink'><li>{t("pickUpSch.placesParga.3")}</li></a>
                        <a href='https://maps.app.goo.gl/84o3Fv5ruJCPhzyk9' className='placesLink'><li>{t("pickUpSch.placesParga.4")}</li></a>
                        <a href='https://maps.app.goo.gl/HNNFveoHebTPHKHeA' className='placesLink'><li>{t("pickUpSch.placesParga.5")}</li></a>
                        <a href='https://maps.app.goo.gl/NrNHL4y2rkoNtzMJ9' className='placesLink'><li>{t("pickUpSch.placesParga.6")}</li></a>
                        <a href='https://maps.app.goo.gl/ArSfMzuph9aX3Agg7' className='placesLink'><li>{t("pickUpSch.placesParga.7")}</li></a>
                    </ul>
                    <ul style={list1Style}>
                        <li>13:00</li>
                        <li>13:05</li>
                        <li>13:10</li>
                        <li>13:15</li>
                        <li>13:20</li>
                        <li>13:25</li>
                        <li>13:30</li>
                    </ul>
                    <ul style={list2Style}>
                        <li>13:30</li>
                        <li>13:35</li>
                        <li>13:40</li>
                        <li>13:45</li>
                        <li>13:50</li>
                        <li>13:55</li>
                        <li>14:00</li>
                    </ul>
                </div>
                
            </div>
            <div className='col-12 col-lg-11 ' style={list1Style}>
                <div className='col-10 mx-auto'>
                    <h3 style={list1Style}>{t("pickUpSch.days.saturday")}</h3>
                </div>
                <div className='col-10 col-lg-9 d-flex flex-row justify-content-between mx-auto'>
                <p>{t("pickUpSch.pickupPoint")}</p>
                    <p>{t("pickUpSch.pickupTime")}</p>
                </div>
                <div className='col-10 col-lg-9 d-flex flex-row justify-content-between mx-auto'>
                    <ul style={list1Style}>
                    <a href='https://maps.app.goo.gl/YNhshwSzu1MZu3X46' className='placesLink'><li>{t("pickUpSch.placesParga.1")}</li></a>
                        <a href='https://maps.app.goo.gl/QYqMzpSRwaJdJb968' className='placesLink'><li>{t("pickUpSch.placesParga.2")}</li></a>
                        <a href='https://maps.app.goo.gl/6YGCTdhoL3mQPKHF9' className='placesLink'><li>{t("pickUpSch.placesParga.3")}</li></a>
                        <a href='https://maps.app.goo.gl/84o3Fv5ruJCPhzyk9' className='placesLink'><li>{t("pickUpSch.placesParga.4")}</li></a>
                        <a href='https://maps.app.goo.gl/HNNFveoHebTPHKHeA' className='placesLink'><li>{t("pickUpSch.placesParga.5")}</li></a>
                        <a href='https://maps.app.goo.gl/NrNHL4y2rkoNtzMJ9' className='placesLink'><li>{t("pickUpSch.placesParga.6")}</li></a>
                        <a href='https://maps.app.goo.gl/ArSfMzuph9aX3Agg7' className='placesLink'><li>{t("pickUpSch.placesParga.7")}</li></a>
                    </ul>
                    <ul style={list1Style}>
                    <li>13:00</li>
                        <li>13:05</li>
                        <li>13:10</li>
                        <li>13:15</li>
                        <li>13:20</li>
                        <li>13:25</li>
                        <li>13:30</li>
                    </ul>
                    
                </div>
                
            </div>
            <hr style={list1Style}></hr>
            <div className='col-12 col-lg-11 ' style={list1Style}>
                <div className='col-10 mx-auto'>
                    <h2>{t("pickUpSch.kanali")}</h2>
                    <h3 style={list1Style}>{t("pickUpSch.days.monday")}</h3>
                    <h3 style={list2Style}></h3>
                </div>
                <div className='col-10 col-lg-9 d-flex flex-row justify-content-between mx-auto'>
                <p>{t("pickUpSch.pickupPoint")}</p>
                    <p>{t("pickUpSch.pickupTime")}</p>
                </div>
                <div className='col-10 col-lg-9 d-flex flex-row justify-content-between mx-auto'>
                    <ul>
                        <a href='https://maps.app.goo.gl/yTskxi6PMjtKJ8a56' className='placesLink'><li>{t("pickUpSch.placesKanali.1")}</li></a>
                        <a href='https://maps.app.goo.gl/jfYj3yyTwXfSXVeM8' className='placesLink'><li>{t("pickUpSch.placesKanali.2")}</li></a>
                        <a href='https://maps.app.goo.gl/RNqBumzBshbUb6Kd6' className='placesLink'><li>{t("pickUpSch.placesKanali.3")}</li></a>
                        <a href='https://maps.app.goo.gl/cbV5Q1zhrm3DX3fG9' className='placesLink'><li>{t("pickUpSch.placesKanali.4")}</li></a>
                        <a href='https://maps.app.goo.gl/3dsshc267tHgWwAv5' className='placesLink'><li>{t("pickUpSch.placesKanali.5")}</li></a>
                    </ul>
                    <ul>
                        <li>8:20 am.</li>
                        <li>8:25 am.</li>
                        <li>8:30 am.</li>
                        <li>8:35 am.</li>
                        <li>8:35 am.</li>
                    </ul>
                </div>
            </div>
            <hr style={list1Style}></hr>
            <div className='col-12 col-lg-11 ' style={list1Style}>
                <div className='col-10 mx-auto'>
                    <h2>{t("pickUpSch.vrahos")}</h2>
                    <h3 style={list1Style}>{t("pickUpSch.days.tuesday")}</h3>
                </div>
                <div className='col-10 col-lg-9 d-flex flex-row justify-content-between mx-auto'>
                <p>{t("pickUpSch.pickupPoint")}</p>
                    <p>{t("pickUpSch.pickupTime")}</p>
                </div>
                <div className='col-10 col-lg-9 d-flex flex-row justify-content-between mx-auto'>
                    <ul>
                    <a href='https://maps.app.goo.gl/vVtdWPQmFZJrS1tx7' className='placesLink'><li>{t("pickUpSch.placesVrahos.1")}</li></a>
                    <a href='https://maps.app.goo.gl/xknuVN6UqA6yzHu18' className='placesLink'><li>{t("pickUpSch.placesVrahos.2")}</li></a>
                    <a href='https://maps.app.goo.gl/5fCpdwSLo1kU5hqP8' className='placesLink'><li>{t("pickUpSch.placesVrahos.3")}</li></a>
                    </ul>
                    <ul>
                        <li>13:25</li>
                        <li>13:30</li>
                        <li>13:35</li>
                    </ul>
                </div>
            </div>



        </div>
    </div>
  );
}

export default PickupScedule;