import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./../../node_modules/bootstrap/dist/js/bootstrap.bundle.js"
import "./cards.css" 


const Cards = ({ className, title, link, hours,navLink,tourNum }) => {

  const {t} = useTranslation();
     // keep data for the booking page
    const navigate = useNavigate();
    const handleNext = () => {
        navigate(navLink, { state: { toursTitle:title , hours:hours, tourNum:tourNum} });
      };
    return (
    <div className={`mainCard d-flex flex-column col-12 col-md-9 col-xl-3 mb-3   ${className}`}>
        <h2 className='tourTitle'>{title}</h2>
        <div className='details col-9'>  
        
           <button className='bookNowButt' onClick={handleNext} >
           {t("cards.bookNow")}
            </button>
            
            <Link  to={link} ><button className='moreInfoButt'>
            {t("cards.moreinfo")}
            </button>
            </Link>
        </div>
    </div>
    );
};
  export default Cards;