import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import "./bookToursDetails.css";
import "./cards.css";
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css";
//import images
import MainImage from './../images/acheronEscape/joshua_kai_famillia.webp'
import ExtImg from './../images/acheronEscape/ExtendedTourImage.webp'
import LAImg from './../images/littleAcheron/litleAcheron.webp'
import OdysseyImg from './../images/odyssey/OddysseyPhoto.webp'
//import components
import Header from './header.js';
import Footer from './footer.js';
import ToursCards from './toursBookNow.js'



function BookToursPage() {
  const {t} = useTranslation();


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const extArray =[ `${t("bookNowpage.1")}`+': 1.5+ '+`${t("bookNowpage.1.1")}` ,`${t("bookNowpage.2")}`+': '+`${t("bookNowpage.2.2")}` ,`${t("bookNowpage.3")}`+`${t("bookNowpage.3.1")}`,`${t("bookNowpage.4")}`,`${t("bookNowpage.5")}`,`${t("bookNowpage.6")}`,`${t("bookNowpage.7")}`,`${t("bookNowpage.8")}`]
  const litAcheronArray =[ `${t("bookNowpage.1")}`+': 1 '+`${t("bookNowpage.1.2")}` ,`${t("bookNowpage.2")}`+': '+`${t("bookNowpage.2.2")}` ,`${t("bookNowpage.3")}`+`${t("bookNowpage.3.2")}`,`${t("bookNowpage.4")}`,`${t("bookNowpage.5")}`,`${t("bookNowpage.6")}`,`${t("bookNowpage.7")}`,`${t("bookNowpage.8")}`]
  const odyssey =[ `${t("bookNowpage.1")}`+': 4+ '+`${t("bookNowpage.1.1")}` ,`${t("bookNowpage.2")}`+': '+`${t("bookNowpage.2.2")}` ,`${t("bookNowpage.3")}`+`${t("bookNowpage.3.3")}`,`${t("bookNowpage.4")}`+'+',`${t("bookNowpage.5")}`,`${t("bookNowpage.6")}`,`${t("bookNowpage.7")}`,`${t("bookNowpage.8")}`]
  const hoursEx =['9:30' , '14:00', '18:00']
  const hoursLA =['10:00' , '12:00', '14:00', '18:30']
  const hoursOd =['15:00']
  const pricesAe = [7,12,25];
  const pricesLA = [0,7,15];
  const pricesOd = [50,50,50];
  return (
      <div className="App">
        <Helmet>
        <title>Dragonfly Kayak: submited</title>
        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WC3GL5Z');
          `}
        </script>
      </Helmet>
          <Header/>
          <section id='bookNowHero'>
          <div className='toursImg'>
          </div>
          </section>
          <div className='tourCardHolder d-flex flex-column col-12 my-4'>
            <ToursCards navLink='/booking-First-Step' tourNum='1' detail={extArray} title='Acheron Escape' price={pricesAe} img={ExtImg} hours={hoursEx} tourLink={'/Acheron-Escape-kayak-Tour'}/>
            
            <ToursCards navLink='/Booking-form' tourNum='3' detail={litAcheronArray} title='Little Acheron'price={pricesLA} img={LAImg} hours={hoursLA} tourLink={'/Litle-Acheron-kayak-tour'}/>
            
            <ToursCards navLink='/booking-First-Step' tourNum='2' detail={odyssey} title='Odyssey'price={pricesOd} img={OdysseyImg} hours={hoursOd} tourLink={'/Odyssey-kayak-tour'}/>
            
          </div>
          
          <Footer/>  
      </div>
  );
}

export default BookToursPage;