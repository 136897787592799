import React from 'react';
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useLocation } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';
//import comps
import "./bookingForm.css";
import './formTours.css'
import Header from './header.js'
import Footer from './footer.js'

//import images
import logo from './../images/BlueLogo.svg'
import backArrow from '../images/arrowBack.svg'


function BookingForm() {
  const { t } = useTranslation();
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const location = useLocation();
  const { toursTitle, hours, img } = location.state || {}; // Access passed data
  const [name, setName] = useState('');
  const [sirName, setSirName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedHour, setSelectedHour] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const pricesAE = [7, 12, 25];
  const pricesLA = [0, 7, 15];
  const pricesOd = [50, 50, 50];

  //////DATE INPUT AND HANDLECHANGE DATE////////////

  const handleChangeDate = (event) => {
    const userInputDate = new Date(event.target.value);
    setSelectedDate(userInputDate);
  };

  //////DATE INPUT AND HANDLECHANGE DATE END////////////

  //////phone///////////////////////////////////
  const handleChangePhone = (event) => {
    // Allow only numbers and backspace
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      setPhone(event.target.value);
    }

    // Update validity based on length
    setIsValid(event.target.value.length >= 10);
  };
  ////////////

  ///////DROPDOWN INPUT NUM OF PLEOPLE//////////
  const [hasInnerClass, setHasInnerClass] = useState(false);
  const outerDivRef = useRef(null);
  const innerDivRef = useRef(null);

  const handleClickOutside = (event) => {
    if (outerDivRef.current && !outerDivRef.current.contains(event.target)) {
      setHasInnerClass(false); // Toggle only if clicked outside the outer div
    }
  };

  useEffect(() => {
    // Add event listener on document mount (or componentDidMount in class components)
    document.addEventListener('click', handleClickOutside);

    // Remove event listener on component unmount (or componentWillUnmount in class components)
    return () => document.removeEventListener('click', handleClickOutside);
  }, []); // Empty dependency array ensures it runs only once

  const handleClick = (event) => {
    // Check if the clicked element is the outer div
    if (event.target === outerDivRef.current) {
      setHasInnerClass(!hasInnerClass);
    }
  };

  ////////BUTTONS FOR NUM OF PEOPLE //////////////
  //for the 2 buttons increase and decrease 
  const [currentValueBabys, setCurrentValueBabys] = useState(0); // Initial value for the input
  const [currentValueKids, setCurrentValueKids] = useState(0);
  const [currentValueAdults, setCurrentValueAdults] = useState(0);
  const [numOfPeople, setNumOfPeople] = useState(0);
  const [priceList, setPriceList] = useState([]);
  useEffect(() => {
    if (toursTitle) {
      let selectedPriceList;
      switch (toursTitle) {
        case 'Acheron Escape':
        case 'Απόδραση στον Αχέροντα': // Greek for Acheron Escape
          selectedPriceList = pricesAE;
          break;
        case 'Little Acheron':
        case "Μικρός Αχέροντας": // Greek for Little Acheron
          selectedPriceList = pricesLA;
          break;
        case 'Odyssey':
        case 'Οδύσσεια': // Greek for Odyssey
          selectedPriceList = pricesOd;
          break;
        default:
          // Handle cases where toursTitle doesn't match any option
          console.warn(`Unknown tour title: ${toursTitle}`);
      }
      setPriceList(selectedPriceList);
    }
  }, [toursTitle]);

  const handleChangeBabys = (event) => {
    // Validate user input to ensure it's a number
    const newValue = parseInt(event.target.value, 10) || 0; // Handle non-numeric input gracefully
    setCurrentValueBabys(newValue);
    setNumOfPeople(newValue + currentValueKids + currentValueAdults);
  };

  const handleChangeKids = (event) => {
    // Validate user input to ensure it's a number
    const newValue = parseInt(event.target.value, 10) || 0; // Handle non-numeric input gracefully
    setCurrentValueKids(newValue);
    setNumOfPeople(newValue + currentValueBabys + currentValueAdults);
    event.stopPropagation(); // Prevent event bubbling
  };

  const handleChangeAdults = (event) => {
    // Validate user input to ensure it's a number
    const newValue = parseInt(event.target.value, 10) || 0; // Handle non-numeric input gracefully
    setCurrentValueAdults(newValue);
    setNumOfPeople(newValue + currentValueKids + currentValueBabys);
  };
  //2 buttons for babys
  const handleIncrementBabys = (event) => {
    setCurrentValueBabys((prevValue) => Math.max(0, prevValue + 1)); // Ensure value stays non-negative
    setNumOfPeople((prevValue) => Math.max(0, prevValue + 1));
    event.stopPropagation(); // Prevent event bubbling
  };

  const handleDecrementBabys = () => {
    setCurrentValueBabys((prevValue) => Math.max(0, prevValue - 1)); // Prevent negative values
    setNumOfPeople((prevValue) => Math.max(0, prevValue - 1));
  };
  //2 buttons for kids
  const handleIncrementKids = () => {
    setCurrentValueKids((prevValue) => Math.max(0, prevValue + 1)); // Ensure value stays non-negative
    setNumOfPeople((prevValue) => Math.max(0, prevValue + 1));
  };

  const handleDecrementKids = () => {
    setCurrentValueKids((prevValue) => Math.max(0, prevValue - 1)); // Prevent negative values
    setNumOfPeople((prevValue) => Math.max(0, prevValue - 1));
  };

  // 2 buttons for adults
  const handleIncrementAdults = () => {
    setCurrentValueAdults((prevValue) => Math.max(0, prevValue + 1)); // Ensure value stays non-negative
    setNumOfPeople((prevValue) => Math.max(0, prevValue + 1));
  };

  const handleDecrementAdults = () => {
    setCurrentValueAdults((prevValue) => Math.max(0, prevValue - 1)); // Prevent negative values
    setNumOfPeople((prevValue) => Math.max(0, prevValue - 1));
  };

  useEffect(() => {
    if (name !== '' && sirName !== '' && isValid && email !== '' && numOfPeople !== 0 && selectedHour !== '') {
      setCanSubmit(true);
    }
    else {
      setCanSubmit(false)
    }
  }, [numOfPeople, name, sirName, phone, email, selectedHour]);

  /////////TIME CHOOSING /////////
  const handleSelectChange = (event) => {
    setSelectedHour(event.target.value); // Update state with selected hour
  };
  /////// TIME CHOOSING END ////////

  const navigate = useNavigate();
  const handleSumbit = async (event) => {
    var totalPrice = currentValueKids * priceList[1] + currentValueBabys * priceList[0] + currentValueAdults * priceList[2];

    navigate('/submited', { state: { totalPrice: totalPrice, transfer:false , img: img, toursTitle: toursTitle, hours: hours, numOfPeople: numOfPeople, time: selectedHour, date: selectedDate.toLocaleDateString(), name: name + " " + sirName, tel: phone, email: email } });
    event.preventDefault();
setSubmitted(true);

const serviceId = 'service_qetsjyd';
const templateId = 'template_fyjwkyp';
const publicKey = 'vWUXH83DJ5Mlc2nQs';

const templateParams = {
  from_name: name,
  from_sirName: sirName,
  from_email: email,
  tour: toursTitle,
  for_hour: selectedHour,
  reply_to: email,
  from_phone: phone,
  from_people: numOfPeople,
  for_date: selectedDate.toLocaleDateString(),
  to_name: 'Dragonfly Kayak',
  place: 'no transfer',
  transfer: 'no transfer',
  message: message,
  kids: currentValueKids,
  babys: currentValueBabys,
  adults: currentValueAdults,
};

emailjs
  .send(serviceId, templateId, templateParams, publicKey)
  .then((response) => {
    console.log('Email send successfully!', response);
    setName('');
    setSirName('');
    setPhone('');
    setEmail('');
    setMessage('');
    setCurrentValueAdults(0);
    setCurrentValueKids(0);
    setCurrentValueBabys(0);
    setNumOfPeople(0);
    setCanSubmit(false);
  })
  .catch((error) => {
    console.error('Error sending email:', error);
  });
};

const handleGoBack = () => {
  if (location.state && location.state.from) {
    navigate(location.state.from);
  } else {
    navigate(-1);  // Fallback to go back one step in the browser history
  }
};

return (
<div>
  <Helmet>
  <title>Booking Acheron Kayak Tour</title>
    <meta name="description" content="Book your kayak in Acheron tour with Pick up from Parga Kanali Preveza perfect for families and couples. Enjoy Acherons beauti and see the Acheron River at its best!!" />
    <script>
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-WC3GL5Z');
      `}
    </script>
  </Helmet>
  <Header />
  <div className='col-12 contactFormImg' id='hero'>
    <div className="mainFormDiv d-flex col-12 col-md-10 col-xl-6 mx-auto justify-content-center py-5">
      <form className='w-100' onSubmit={handleSumbit}>
      <div className='d-flex mb-4 '>
                        <button onClick={handleGoBack} className='backBtn col-1 align-items-center'>
                            <img src={backArrow }></img>
                        </button>
                        <div className='d-flex flex-row col-6 gap-2 mx-auto justify-content-start align-items-center '>
                            
                            <div className='col-3 step-item step-item-active'></div>
                            <div className='col-3 step-item step-item-active'></div>
                            <div className='col-3 step-item step-item-active'></div>
                        </div>
                    </div>
        <div className='d-flex flex-column col-11 align-items-center gap-3 mx-auto'>
          <div className='col-12 d-flex flex-row imgDetHolder'>
            <div>
              <img src={logo} alt='Dragonfly Kayak in Acheron Logo' />
            </div>
            <div>
              <h4>{t("BookingForm.booking")} </h4>
              <p>{toursTitle} , {t("BookingForm.people")} {numOfPeople} , {t("BookingForm.date")} {selectedDate.toLocaleDateString()}</p>
            </div>
          </div>
          <div className='col-12 d-flex flex-column flex-lg-row justify-content-between gap-3 gap-lg-0'>
            <div className=' col-12 col-lg-5'>
              <label htmlFor="name">{t("BookingForm.name")}</label>
              <input type="text" className="form-control" id="name" placeholder={t("BookingForm.namePH")} onChange={(e) => setName(e.target.value)} required value={name} />
            </div>
            <div className='col-12 col-lg-5'>
              <label htmlFor="sirName">{t("BookingForm.surName")}</label>
              <input type="text" className="form-control" id="sirName" placeholder={t("BookingForm.surNamePH")} required value={sirName} onChange={(e) => setSirName(e.target.value)} />
            </div>
          </div>
          <div className='col-12 d-flex flex-column flex-lg-row justify-content-between gap-3 gap-lg-0'>
            <div className=' col-12 col-lg-5'>
              <label htmlFor="name">{t("BookingForm.numPeople")}</label>
              <div className="form-control myList" ref={outerDivRef} onClick={handleClick}>{t("BookingForm.numPeopleP")} {numOfPeople}
                <div id='myListDropdown' className={`inner-div px-3 py-3 ${hasInnerClass ? 'activeList' : ''}`}>
                  <div className='col-12 d-flex flex-row justify-content-between gap-2'>
                    <p>{t("BookingForm.firstAge")}</p>
                    <div className='col-auto d-flex flex-row justify-content-end'>
                      <input
                        type='number'
                        value={currentValueBabys}
                        onChange={handleChangeBabys} />
                      <button type="button" onClick={handleDecrementBabys}>-</button>
                      <button type="button" onClick={handleIncrementBabys}>+</button>
                    </div>
                  </div>
                  <hr></hr>
                  <div className='col-12 d-flex flex-row justify-content-between gap-2'>
                    <p>{t("BookingForm.secAge")}</p>
                    <div className='col-auto d-flex flex-row justify-content-end'>
                      <input
                        type='number'
                        value={currentValueKids}
                        onChange={handleChangeKids} />
                      <button type="button" onClick={handleDecrementKids}>-</button>
                      <button type="button" onClick={handleIncrementKids}>+</button>
                    </div>
                  </div>
                  <hr></hr>
                  <div className='col-12 d-flex flex-row justify-content-between gap-2'>
                    <p>{t("BookingForm.thirdAge")}</p>
                    <div className='col-auto d-flex flex-row justify-content-end'>
                      <input
                        type='number'
                        value={currentValueAdults}
                        onChange={handleChangeAdults} />
                      <button type="button" onClick={handleDecrementAdults}>-</button>
                      <button type="button" onClick={handleIncrementAdults}>+</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12 col-lg-5'>
              <label htmlFor="name">{t("BookingForm.dateInput")}</label>
              <input
                className=' form-control'
                type="date"
                id="selectedDate"
                name="selectedDate"
                value={selectedDate.toISOString().slice(0, 10)}
                onChange={handleChangeDate} />
            </div>
          </div>
          <div className='col-12 d-flex flex-column flex-lg-row justify-content-between gap-3 gap-lg-0'>
            <div className='col-12 col-lg-5'>
              <label htmlFor="name">{t("BookingForm.tel")}</label>
              <input type="number" className="form-control phoneInput" id="phoneNumber" placeholder="0030 306940524341" minLength='10' required value={phone} onChange={handleChangePhone} />
            </div>
            <div className='col-12 col-lg-5'>
              <label htmlFor="name">Email*</label>
              <input type="text" className="form-control" id="mail" placeholder="Dragonflykayak@yahoo.com" required value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
          </div>
          <div className='col-12 d-flex flex-column justify-content-between gap-3 gap-lg-0'>
            <div className="col-12">
              <div className='clockInput col-12 d-flex flex-column flex-lg-row  justify-content-start'>
                <div className='col-12 col-lg-5 theHours'>
                <label htmlFor="name">Hour*</label>
                  <select
                    id="selectedHour"
                    className="form-select"
                    onChange={handleSelectChange}
                    value={selectedHour}
                  >
                    <option value="">Select Hour</option>
                    {hours.map((hour) => (
                      <option key={hour} value={hour}>{hour}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-12">
              <label htmlFor="message">{t("BookingForm.message")}</label>
              <textarea className="form-control" value={message} onChange={(e) => setMessage(e.target.value)} id="message" rows="5"></textarea>
            </div>
          </div>

          <div className='col-12 d-flex flex-column flex-lg-row justify-content-end gap-3 gap-lg-0 tranfer'>

            <div className="col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end ">
              <button type="submit" className={`disabledButton ${canSubmit ? ' myBtn' : ''}`} disabled={!canSubmit}>{t("BookingForm.send")}</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <Footer />
</div>
);
}

export default BookingForm;


