import React from 'react';
import "./tourLitleAcheron.css";
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//import images
import LAImg from './../images/littleAcheron/litleAcheron.webp'
import img1 from './../images/littleAcheron/img1.webp'
import img2 from './../images/littleAcheron/img2.webp'
import img3 from './../images/littleAcheron/img3.webp'
import img4 from './../images/littleAcheron/img4.webp'
import img5 from './../images/littleAcheron/img5.webp'
import img6 from './../images/littleAcheron/img6.webp'
import img7 from './../images/littleAcheron/img7.webp'
import img8 from './../images/littleAcheron/img8.webp'
import Logo from './../images/BlueLogo.png'
import vestImg from './../images/tours-icons/vest.svg'
import kayakImg from './../images/tours-icons/kayak.svg'
import waterImg from './../images/tours-icons/water_bottle.svg'
import dropletImg from './../images/tours-icons/droplet-half.svg'
import parkingImg from './../images/tours-icons/parking.svg'
import busImg from './../images/tours-icons/bus.svg'
//import components
import Header from './header.js';
import Footer from './footer.js';
import ToursCarousel from './toursCarousel.js';






function TourLitleAcheron() {
  const {t} = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  
  const LAImgs = [img1,img2,img3,img4,img5,img6,img7,img8];
  const hours = ['10:00','12:00','16:00','18:30'];
  //for the button to be sticky
  const [isSticky, setIsSticky] = useState(false);
  const buttonRef = useRef(null);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const buttonHeight = buttonRef.current ? buttonRef.current.offsetHeight : 0; // Get button height
    const windowHeight = window.innerHeight;
    setIsSticky(scrollY + windowHeight  >= buttonRef.current.offsetTop + buttonHeight);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // Empty dependency array to run effect only once

  ////////to go to the booking form///////////
  const handleNext = () => {
    navigate('/Booking-form', { state: { toursTitle:t("LitleAcheron.title") , hours:hours,tourNum:'3' } }); 
  };



    // keep data for the booking page
  const navigate = useNavigate();



  ////////to go to the booking form end//////////


  //
  return (
    
      <div className="App">
        <Helmet>
        <title>Dragonfly Kayak: Little Acheron</title>
        <meta name="description" content="Experience the magic of the Acheron River on a gentler scale with Dragonfly Kayak's Little Acheron tour. Perfect for families and beginners, enjoy stunning scenery and a relaxing kayaking adventure in Parga. Book your Little Acheron tour today!" />

        <script>
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-WC3GL5Z');
          `}
        </script>
     
     </Helmet>
          <Header/>
          <section id='tour-little-Acheron-hero'>
            <div className='toursHero'>
              <img src={LAImg} alt='Parga kayak'/>
            </div>
            </section>
            <div className='d-flex flex-column col-12 col-lg-auto align-items-start align-items-lg-center tourPageDetails my-4'>
              <div className='d-flex col-12 col-lg-6 align-items-start  justify-content-start tourPageDetails'>
                <div>
                  <img src={Logo} alt='Parga kayak' />
                </div>
                <div className='d-flex flex-column '>
                    <h2>{t("LitleAcheron.title")}</h2>
                    <p>{t("LitleAcheron.subTitle")}</p>
                </div>
              </div>
              <div className='d-flex flex-column flex-lg-row col-12 col-lg-6 justify-content-start justify-content-lg-start align-items-center'>
                <div className='d-flex flex-column col-12 col-lg-auto px-4 borderDiv align-items-start align-items-lg-start'>
                
                  <div className='spanPHolder'>
                    <span>
                    {t("LitleAcheron.price")}
                    </span>
                    <div className='d-flex flex-row priceDiv'>
                      <p>0-6 {t("AcheronEscape.yo")}  {t('LitleAcheron.free')}</p>
                      <p> 6-12 {t("AcheronEscape.yo")}  7€</p>
                      <p>12+ {t("AcheronEscape.yo")} 15€</p>
                    </div>
                  </div>
                  <div>
                    <span>
                    {t("LitleAcheron.ToursEveryDayAt")}
                    </span>
                    <div className='d-flex flex-row priceDiv'>
                      <p>10:00</p>
                      <p>12:00 </p>
                      <p>16:00</p>
                      <p>18:30</p>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-column px-4 gap-2 col-12 col-lg-auto mt-4 align-items-start'>
                  <div className='d-flex flex-row gap-5 col-6 col-lg-auto justify-content-between'>
                    <div className='d-flex flex-column'>
                      <span>{t("LitleAcheron.Duration")}</span>
                      <p>1 {t('bookNowpage.1.2')}</p>
                    </div>
                    <div className='d-flex flex-column'>
                      <span>{t("LitleAcheron.Ages")}</span>
                      <p>{t("LitleAcheron.all")}</p>
                    </div>
                  </div>
                  <div className='d-flex flex-row gap-5 col-6 col-lg-auto justify-content-between'>
                    <div className='d-flex flex-column'>
                      <span style={{ whiteSpace: 'pre-line' }}>{t("LitleAcheron.Difficulty")}</span>
                      <p>1/6</p>
                    </div>
                    <div className='d-flex flex-column'>
                      <span>{t("LitleAcheron.Distance")}</span>
                      <p>3.1 {t("LitleAcheron.km")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 d-flex flex-row paraNav'>
              <a href='#synopsi'>{t("LitleAcheron.subNav.Summary")}</a>
              <a href='#iportDet'>{t("LitleAcheron.subNav.importantInformation")}</a>
              <a href='#faq'>{t("LitleAcheron.subNav.FAQs")}</a>
              <button className='tourPageBookButton' onClick={handleNext}>{t("Odyssey.subNav.bookNow")}</button>
            </div>
            <div  id="my-button" ref={buttonRef} className={isSticky ? "sticky" : "bookNowStickyDiv d-flex flex-row justify-content-center align-items-center"}>
            <button className='tourPageBookButton' onClick={handleNext}>{t("Odyssey.subNav.bookNow")}</button>
            </div>
            <hr className='col-12 tourHr'></hr>
            <section id="synopsi">
              <div className='col-12 d-flex flex-column  align-items-center'>
                <div className='col-12 col-md-10 col-xl-7 px-2 px-lg-0  d-flex- flex-column align-items-center'>
                  <h3>{t("LitleAcheron.subNav.Summary")}</h3>
                  <div className=' col-12 d-flex flex-column flex-lg-row gap-3 gap-lg-0 justify-content-between'>
                    <div className='articleDiv col-12 col-lg-6'>
                      <article style={{ whiteSpace: 'pre-line' }}>
                      {t("LitleAcheron.summaryText")}
                      </article>
                    </div>
                    <div className='d-flex flex-column col-12 col-lg-6 px-lg-3 rightDiv justify-content-start align-items-lg-center'>
                      <h5>{t("LitleAcheron.Allowances.title")}</h5>
                      <ul className='innerUL1'>
                        <li>
                          <img className='benefitsIcons' src={vestImg} alt='Parga kayak'/>
                          {t("LitleAcheron.Allowances.first")}
                        </li>
                        <li>
                          <img className='benefitsIcons' src={kayakImg} alt='Parga kayak' />
                          {t("LitleAcheron.Allowances.secont")}</li>
                        <li>
                          <img className='benefitsIcons' src={waterImg} alt='Parga kayak' />
                          {t("LitleAcheron.Allowances.third")}
                        </li>
                        <li>
                          <img className='benefitsIcons' src={dropletImg} alt='Parga kayak' />
                          {t("LitleAcheron.Allowances.fourth")}
                        </li>
                        <li>
                          <img className='benefitsIcons' src={parkingImg} alt='Parga kayak' />
                          {t("LitleAcheron.Allowances.fifth")}
                        </li>
                        <li>
                          <img className='benefitsIcons' src={busImg} alt='Parga kayak' />
                          {t("LitleAcheron.Allowances.sixth")}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id='iportDet'>
              <div className='col-12 d-flex flex-column py-5 align-items-center'>
                <div className='col-12 col-md-10 col-xl-7 px-2 px-lg-0 d-flex- flex-column align-items-center'>
                  <h3>{t("LitleAcheron.subNav.importantInformation")}</h3>
                  <div className=' col-12 d-flex flex-column flex-lg-row gap-3 gap-lg-0 justify-content-between'>
                    <div className='articleDiv col-12 col-lg-6'>
                      <li>
                        <span>{t("LitleAcheron.impInfo.miniFirst")}</span>
                        <p>{t("LitleAcheron.impInfo.first")}</p>
                      </li>
                      <li>
                        <span>{t("LitleAcheron.impInfo.miniSecont")}</span>
                        <p>{t("LitleAcheron.impInfo.secont")}</p>
                      </li>
                      <li>
                        <span>{t("LitleAcheron.impInfo.miniThird")}</span>
                        <p>{t("LitleAcheron.impInfo.third")}</p>
                      </li>
                      <li>
                        <span>{t("LitleAcheron.impInfo.miniFourth")}</span>
                        <p>{t("LitleAcheron.impInfo.fourth")}</p>
                      </li>
                      <li>
                        <span>{t("LitleAcheron.impInfo.miniFifth")}</span>
                        <p>{t("LitleAcheron.impInfo.fifth")}</p>
                      </li>
                    </div>
                    <div className='d-flex flex-column col-12 col-lg-6 px-lg-3 rightDiv justify-content-start align-items-lg-center'>
                      <h5>{t("LitleAcheron.impInfo.need")}</h5>
                      <ul className='innerUl'>
                        <li>{t("LitleAcheron.impInfo.Swimsuit")}</li>
                        <li>{t("LitleAcheron.impInfo.Hat")}</li>
                        <li>{t("LitleAcheron.impInfo.T-shirt")}</li>
                        <li>{t("LitleAcheron.impInfo.Camera")}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section id='faq'>
              <div className='col-12 d-flex flex-column justify-content-center align-items-center mb-5'>
              
                <div className='col-12 col-md-10 col-xl-7 px-2 px-lg-0 d-flex flex-column articleDiv last gap-3'>
                <h3>FAQs</h3>
                  <li>
                    <span>{t("LitleAcheron.faqFirst")}</span>
                    <p>{t("LitleAcheron.faqFirstAns")}</p>
                  </li>
                  <li>
                    <span>{t("LitleAcheron.faqSecont")}</span>
                    <p>{t("LitleAcheron.faqSecontAns")}</p>
                  </li>
                </div>
                <div className='col-12 tourCarouselHolder mt-5 mb-3'>
                    <ToursCarousel images={LAImgs} numImages={LAImgs.length}/>
              </div>
              </div>
            </section>
          <Footer/>  
      </div>
  );
}

export default TourLitleAcheron;