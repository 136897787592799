// This code uses the Basic Display API (deprecated)

import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import "./instagram.css";

const MyPhotosAndVideos = () => {
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Initial loading state
  const [accessToken, setAccessToken] = useState(
    "IGQWRPd19UUElmdVB3akZAyRkVrbkZAjazIwbzVUWWk4QzdGUFlqR09HeVN0NkxVcFpnSG1IM3dZAQVJtUF9pNHpkWktvUFlLdmtWQWZATdktNeTBtVngzM3VfbmhKZAkJLajlOSjBRc05NcV8zaE9TTm1NMUZALX2xISEUZD"
  ); // Replace with your access token

  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await axios.get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&access_token=${accessToken}`);
        const media = response.data.data;
        const separatedMedia = media.reduce(
          (acc, item) => {
            if (item.media_type === "IMAGE") {
              acc.photos.push(item);
            } else if (item.media_type === "VIDEO") {
              acc.videos.push(item);
            }
            return acc;
          },
          {photos: [], videos: []}
        );
        setPhotos(separatedMedia.photos);
        setVideos(separatedMedia.videos);
       
      } catch (error) {
        console.error("Error fetching media:", error);
      } finally {
        setIsLoading(false); // Ensure loading state updates even on errors
      }
    };

    if (accessToken) {
      fetchMedia();
    }
  }, [accessToken]);





  /////buttons//////
  const [scrollPosition, setScrollPosition] = useState(0); // Initial scroll position (0%)
  const contentRef = useRef(null); // Ref to hold the content element
  const windowWidth = useRef(window.innerWidth); // Ref to store window width

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      windowWidth.current = window.innerWidth;
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const scrollAmount = windowWidth.current -0; // Viewport width minus 20px

  const moveRight = () => {
    // Calculate new scroll position (avoid exceeding viewport width)
    
    const newScrollPosition = Math.max(scrollPosition + 100, 100);
    setScrollPosition(newScrollPosition);

    if (contentRef.current) {
      // Update content element's scroll position using scrollLeft
      
      contentRef.current.scrollLeft += scrollAmount; // Scroll left by adjusted width
    }

   
  };

  const moveLeft = () => {
    // Calculate new scroll position (avoid exceeding 0)
    
    const newScrollPosition = Math.max(scrollPosition - 100, 0);
    setScrollPosition(newScrollPosition);

    if (contentRef.current) {
      // Update content element's scroll position using scrollLeft
      contentRef.current.scrollLeft -= scrollAmount; // Scroll left by adjusted width
    }
  };

  return (
    <div>
      {" "}
      {isLoading ? (
        <div className="loading-screen">
          {" "}
          {/* Your loading screen content here */} <p> Loading your photos and videos... </p>{" "}
        </div>
      ) : (
        <div className="my-5">
          <h2 className="mb-5"> <span>Our</span> Instagram </h2>{" "}
          <div>
            <div className=" d-flex ulHolder">
              <button className="prevButton" onClick={moveLeft}>{`<`}</button>
              <button className="nextButton" onClick={moveRight}>{`>`}</button>
            {photos.length > 0 && (
              <ul className=" d-flex flex-column flex-wrap col-12 gap-1"  ref={contentRef}>
                {" "}
                {photos.map(photo => (
                  <li key={photo.id}  className="listForImg col-lg-3 col-6 ">
                    <img src={photo.media_url} alt={photo.caption}>
                    </img>{" "}
                    <p>{photo.caption}</p>
                  </li>
                  
                ))}{" "}
              </ul>
            )}{" "}
            </div>
          </div>
          {photos.length === 0 && <p> No media found. </p>}{" "}
        </div>
      )}{" "}
    </div>
  );
};

export default MyPhotosAndVideos;