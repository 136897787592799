import React, { useState, useEffect } from 'react';
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from './header.js';
import Footer from './footer.js';
import backArrow from '../images/arrowBack.svg'
import "./firstBooking.css";

const SecBooking = () => {
    const { t } = useTranslation();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const location = useLocation();
    const { toursTitle, transfer, tourNum } = location.state || {};
    const navigate = useNavigate();

    // State to manage selected dropdown values and placeChoosed
    const [pickUpValue, setPickUpValue] = useState('');
    const [placeChoosed, setPlaceChoosed] = useState(0); // 0 means no selection

    const handleChange = (event) => {
        const index = Array.from(event.target.parentNode.children).indexOf(event.target);
        setPlaceChoosed(index-1); // Set placeChoosed based on index (+1 because index is zero-based)
        setPickUpValue(event.target.value);
    };

    const handleNextClick = () => {
        if (pickUpValue) {
            console.log('Selected Item:', pickUpValue);
            console.log('Place Choosed:', placeChoosed);
            navigate('/booking-form-transfer', { state: { toursTitle, pickUpValue, transfer, placeChoosed ,tourNum} });
        } else {
            alert('Please select an item');
        }
    };


  const handleGoBack = () => {
    if (location.state && location.state.from) {
      navigate(location.state.from);
    } else {
      navigate(-1);  // Fallback to go back one step in the browser history
    }
  };

    const renderLists = () => {
        switch (tourNum) {
            case '1':
                return (
                    <>
                        <select onChange={handleChange} value={pickUpValue} className="form-select mb-3">
                            <option value="" >{t('transferBooking.Parga')}</option>
                            <option value="Valtos Beach at 13:00">Valtos Beach 13:00</option>
                            <option value="MED at 13:05">MED 13:05</option>
                            <option value="Eleni Hotel at 13:10">Eleni Hotel 13:10</option>
                            <option value="ITS Office at 13:15">ITS Office 13:15</option>
                            <option value="Rezi Hotel at 13:20">Rezi Hotel 13:20</option>
                            <option value="Firestation at 13:25">Firestation 13:25</option>
                            <option value="Lychnos at 13:30">Lychnos 13:30</option>
                        </select>
                        <select onChange={handleChange} value={pickUpValue} className="form-select mb-3">
                            <option value="" >{t('transferBooking.Kanali')}</option>
                            <option value="Ionian Theoxenia at 8:20">Ionian Theoxenia 8:20 </option>
                            <option value="Must Hotel at 8:25">Must Hotel 8:25 </option>
                            <option value="Nama Seaside Villas at 8:30">Nama Seaside Villas 8:30 </option>
                            <option value="Nepheli Hotel Kanali at 8:35">Nepheli Hotel Kanali 8:35 </option>
                            <option value="Ninos Grand at 8:35">Ninos Grand 8:35 </option>
                        </select>
                        <select onChange={handleChange} value={pickUpValue} className="form-select mb-3">
                            <option value="" >{t('transferBooking.Vrahos')}</option>
                            <option value="House Maria at 13:25">House Maria 13:25</option>
                            <option value="Karnagio at 13:30">Karnagio 13:30</option>
                            <option value="Moonshine Bar at 13:35">Moonshine Bar 13:35</option>
                        </select>
                    </>
                );
            case '2':
                return (
                    <>
                        <select onChange={handleChange} value={pickUpValue} className="form-select mb-3">
                            <option value="" >{t('transferBooking.Parga')}</option>
                            <option value="Valtos Beach at 13:30">Valtos Beach 13:30</option>
                            <option value="MED at 13:35">MED 13:35</option>
                            <option value="Eleni Hotel at 13:40">Eleni Hotel 13:40</option>
                            <option value="ITS Office at 13:45">ITS Office 13:45</option>
                            <option value="Rezi Hotel at 13:50">Rezi Hotel 13:50</option>
                            <option value="Firestation at 13:55">Firestation 13:55</option>
                            <option value="Lychnos at 14:00">Lychnos 14:00</option>
                        </select>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className='vh-100 d-block fade-in'>
            <Helmet>
                <title>Dragonfly Kayak: Pick Up</title>
                <meta name="description" content={`Embark on an unforgettable adventure with Dragonfly Kayak's ${toursTitle} tour. Book your ${toursTitle} in Parga today!`} />
                <script>
                    {`
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-WC3GL5Z');
                    `}
                </script>
            </Helmet>
            <Header />
            
            <div className='d-flex flex-column justify-content-center align-items-center fbMainDiv'>
                <div className='d-flex flex-column gap-3 px-3 py-5 col-11 col-xl-4 rounded fbInnerDiv'>
                    <div className='d-flex '>
                        <button onClick={handleGoBack} className='backBtn col-1 align-items-center'>
                            <img src={backArrow }></img>
                        </button>
                        <div className='d-flex flex-row col-6 gap-2 mx-auto justify-content-start align-items-center '>
                            
                            <div className='col-3 step-item step-item-active'></div>
                            <div className='col-3 step-item step-item-active'></div>
                            <div className='col-3 step-item'></div>
                        </div>
                    </div>
                    
                    <h2 className='mx-auto color-white'>{t('transferBooking.2')} {toursTitle}</h2>
                    {renderLists()}
                    <button 
                        onClick={handleNextClick} 
                        className="btn mt-3" 
                        disabled={!pickUpValue}>
                        {pickUpValue ? `${t('transferBooking.4')} ${pickUpValue}` : `${t('transferBooking.3')}`}
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default SecBooking;
