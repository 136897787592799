import React from 'react';
//import bootstrap
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css"
//import components
import "./toursCarousel.css";









function ToursCarousel({ images, numImages }) {
  return (
    <div className="mainCarousel col-12 d-flex flex-row gap-2">
      <ul className="slides d-flex flex-row gap-1 gap-lg-2 " style={{ '--num-images': numImages }}>
        {images.map((image, index) => (
          <li key={index} >
            <img src={image} alt={`kayak tours in Parga and Acheron ${index + 1}`} />
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ToursCarousel;