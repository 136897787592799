import React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from './i18n'; // Import your i18n instance
import { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import "./../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "./../../node_modules/bootstrap/dist/js/bootstrap.bundle.js"
import "./header.css"
import logo from './../images/BlueLogo.png';
import lang from './../images/language.png';




function Header() {
  const {t} = useTranslation();


  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0); // Update state based on scroll position
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove event listener on unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []); // Empty dependency array to run effect only once







  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const handleClick = () => {
    const newLanguage = currentLanguage === 'en' ? 'gr' : 'en';
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
  };

  useEffect(() => {
    const handleChange = (lng) => {
      setCurrentLanguage(lng);
    };

    i18n.on('languageChanged', handleChange); 

    return () => i18n.off('languageChanged', handleChange); 
  }, [i18n]);

    return(
      <nav className={`navbar navbar-expand-lg ${isScrolled ? 'scrolled' : ''}`}>
        <div className='container-fluid text-center'>
        <div className="container-fluid row justify-content-between">
        <Link className="logoText navbar-brand col-3" style={{ color: `rgb(14,110,132)` , fontSize:'25px', fontWeight: "bold"}} to="/#Hero">
          <img className='logoImage me-2' src={logo} alt="Logo Dragonfly kayak Acheron" />
          Dragonfly Kayak</Link> 
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className=" px-2 collapse navbar-collapse col-xl-7 col-8 justify-content-between" id="navbarNavDropdown">
          <ul className="navbar-nav">
          <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              {t("header.tours")}
              </a>
              <ul className="dropdown-menu" >
                <li><Link className="dropdown-item" to="/Acheron-Escape-kayak-Tour">{t("header.AE")}</Link></li>
                <li><Link className="dropdown-item" to="/Litle-Acheron-kayak-tour">{t("header.LA")}</Link></li>
                <li><Link className="dropdown-item" to="/Odyssey-kayak-tour">{t("header.Odyssey")}</Link></li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to='/Contact-Form' >{t("header.contact")}</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Pickup-Schedule" >{t("header.pickUp")}</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/Kayak-Tour-Book" style={{fontWeight: "500"}} >{t("cards.bookNow")}</Link>
            </li>
            
          </ul>
          <a>
          <button className='border-0 ' onClick={handleClick}>
            <img className='bg-white' src={lang} alt="Lang" style={{width: "35px"}}/>
          </button>
        </a>
        </div>
      </div>
        </div>
    </nav>
    );
  };

export default Header;